import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentMethodModel } from 'src/app/models/payment.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

export enum PaymentMethodItemProcessingStatusEnum {
  none = 'none',
  loading_set_as_default = 'as_default',
  loading_revoke = 'revoke',
  done = 'done',
}
@Component({
  selector: 'app-payment-method-item',
  templateUrl: './payment-method-item.component.html',
  styleUrls: ['./payment-method-item.component.css']
})
export class PaymentMethodItemComponent implements OnInit {
  paymentMethodItemProcessingStatusEnum = PaymentMethodItemProcessingStatusEnum;
  processing: PaymentMethodItemProcessingStatusEnum = PaymentMethodItemProcessingStatusEnum.none;
  @Input() item: PaymentMethodModel;
  constructor(private sharedProfileService: SharedProfileService, private toastr: ToastrService) { }

  ngOnInit() {
  }


  async setAsDefault() {
    this.processing = PaymentMethodItemProcessingStatusEnum.loading_set_as_default;
    await this.sharedProfileService.setDefaultPaymentMethod(this.item.id);
    setTimeout(async () => {
      await this.sharedProfileService.getAllPaymentMethodsForUser(false);
      this.processing = PaymentMethodItemProcessingStatusEnum.done;
    }, 500);

  }


  async deleteOfferAlert() {
    return Swal.fire({
      html: `
      Are you sure you would like to delete selected card ?
        `,
      icon: 'error',
      width: '600px',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger m-0',
        cancelButton: 'btn btn-light m-0',
        input: 'form-check form-check-danger',
        validationMessage: 'alert w-75 mx-auto bg-light-danger border-danger'
      },
      input: 'checkbox',
      inputPlaceholder: `I am sure that i want to delete this card.`,
      inputValidator: (value: any) => {
        
        if (!!value) {
          return null;
        } else {
          return 'Read carefully and check the agreement';
        }
      },
      preConfirm: async (inputValue: any) => {
        let deleteResponse = await this.sharedProfileService.deletePaymentMethod(this.item.id);
        this.item.deleted = true;
        this.toastr.success(`Payment Method Revoked Successfully`);
        setTimeout(() => {
          this.sharedProfileService.getAllPaymentMethodsForUser();
        }, 1000);


        return deleteResponse;
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        return result.value;
      }
    });
  }

}
