import { PaymentService } from '../../shared/services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService, ApiService } from '../../shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { Country } from 'src/app/models/country';
import { matchValues } from '../../shared/services/validators';
import {
  emailRegex,
  passwordRegex,
  passwordWhiteSpace,
  usernameRegex,
} from '../../shared/services/regex';
import { RegisterModel } from 'src/app/models/register';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import Swal from 'sweetalert2';
import { ProgressSpinnerService } from '../../shared/services/progress-spinner.service';

import { CountdownComponent } from 'ngx-countdown';
import { CouponPlansResult, Plan, PlansResult } from 'src/app/models/plans';
import {
  PlansPurchaseModeEnum,
  UserSubscriptionStatus,
} from '../choose-plan/choose-plan.component';
import { Subscription } from './../../models/user';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';
import {
  OntTimePaymentOutputBtcpay,
  PaymentMethodModel,
} from 'src/app/models/payment.model';
import { lastValueFrom } from 'rxjs';
import { cardAnimation } from 'src/app/shared/animations/animations';
import {
  LoginMediaItem,
  ThemeMediaTypeEnum,
} from '../user-dashboard/portal/portal.model';
import { AnimatedLoaderType } from 'src/app/shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';

export enum PaymentMethodEnum {
  none,
  stripe,
  btcpay,
}

export enum RegisterErrorType {
  none,
  username_exist,
  email_exist,
}

export enum PaymentProcessType {
  save_and_register,
  save_only,
  pay_and_save,
  just_pay,
}

// export enum PinCodeErrorType {
//   none, invalid_code, expired_code
// }

@Component({
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.css'],
  animations: [
    cardAnimation,
    trigger('inOutSlide', [
      transition(':enter', [
        style({ maxHeight: '0px', minHeight: '0', opacity: 0 }),
        animate('0.3s ease-out', style({ maxHeight: '200px', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ maxHeight: '200px', opacity: 1 }),
        animate(
          '0.2s ease-out',
          style({ maxHeight: '0px', minHeight: '0', opacity: 0 })
        ),
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.5s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('text-animation', [
      transition(':enter', [
        style({ maxHeight: '0px', minHeight: '0', opacity: 0 }),
        animate('0.6s ease-out', style({ maxHeight: '200px', opacity: 1 })),
      ]),
    ]),
  ],
})
export class WaitListComponent implements OnInit {
  animatedLoaderType = AnimatedLoaderType;
  _images: LoginMediaItem[] = [
    {
      title: 'Create',
      content: `Learmo enables you to craft various digital educational materials, such as digital books and coaching programs, to showcase your expertise.`,
      image: 'assets/images/svg/system/login-features/create.svg',
      type: ThemeMediaTypeEnum.image,
      mainColor: '#0963f6',
    },
    {
      title: 'Grow',
      content: `Learmo offers a comprehensive range of marketing tools and templates, allowing you to quickly generate new content, launch campaigns, and set up sales funnels.`,
      image: 'assets/images/svg/system/login-features/grow.svg',
      type: ThemeMediaTypeEnum.image,
      mainColor: '#FFC727',
    },
    {
      title: 'Manage',
      content: `Effectively manage your educational growth with Learmo's integrated CRM system, accept payments through BTC, Stripe, and PayPal integrations, and leverage analytics to make informed business decisions.`,
      image: 'assets/images/svg/system/login-features/manage.svg',
      type: ThemeMediaTypeEnum.image,
      mainColor: '#92E3A9',
    },
  ];

  plansPurchaseModeEnum = PlansPurchaseModeEnum;
  // Register Form
  registerform = new UntypedFormGroup({
    fullName: new UntypedFormControl('fullname'),
    email: new UntypedFormControl('email'),
    username: new UntypedFormControl('username'),
    password: new UntypedFormControl('password'),
  });

  // UI Variables
  loading: boolean = false;

  // currentBundle: Plans;

  // Password Errors Variables
  isHasNumber: boolean = false;
  isContainCases: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly api: ApiService,
    private readonly router: Router,
    private readonly aroute: ActivatedRoute,
    public loadingService: ProgressSpinnerService,
    public paymentService: PaymentService,
    public cdr: ChangeDetectorRef
  ) {
    // this.selectedPlan.id = this.aroute.snapshot.queryParams['bundleID'];
    // this.fullName = this.aroute.snapshot.queryParams['uname'];
    // this.email = this.aroute.snapshot.queryParams['email'];
  }

  async ngOnInit() {
    if (!!this.authenticationService.currentUserValue) {
      this.router.navigate(['dashboard']);
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  onSignupStart() {
    this.loading = true;
  }

  onSignupDone() {
    this.loading = false;
  }

  onSignupError() {
    this.loading = false;
  }

  termsAndConditions() {
    Swal.fire({
      customClass: {
        confirmButton: 'btn btn-success m-1',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      title: 'Terms And Conditions',
      width: '75%',
      html: "<div id='general1' class='collapse show' role='tabpanel' aria-labelledby='headingOne'><p>An admin theme is a HTML, CSS, JavaScript template that is used to build the user interface for the admin side of a web application. They usually contain the HTML, CSS code for designs that you can use while building your admin dashboard.</p><p>Using admin themes in your projects saves you a lot of time and money simply because you don't need to design and code the frontend templates from scratch and you can get your application up and running in a short time.</p></div>",
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      showCancelButton: false,
    }).then((res) => {});
  }

  passwordUIValidator(value: string) {
    this.isHasNumber = this.hasNumber(value);
    this.isContainCases = this.containCases(value);
  }

  hasNumber(value: string) {
    if (/\d/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  containCases(value: string) {
    if (
      /^(?=.*?[A-Z])(?=.*?[a-z])[A-Za-z\/\]\}\{\d~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<,>\.\?\/ ]{0,}$/.test(
        value
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
