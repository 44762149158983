// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: unset, serif;
  height: 100vh;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
  background-repeat: no-repeat;
 background-image: url(https://i.pinimg.com/originals/0e/c0/db/0ec0dbf1e9a008acb9955d3246970e15.gif);
    height: 600px;
    background-position: center;
 }


 .four_zero_four_bg h1{
 font-size:80px;
 }

  .four_zero_four_bg h3{
			 font-size:80px;
			 }

			 .link_404{
	color: #fff!important;
    padding: 10px 20px;
    background: #007bff;
    margin: 20px 5px;
    border-radius: 5px;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}
`, "",{"version":3,"sources":["webpack://./src/app/components/not-found-page/not-found-page.component.css"],"names":[],"mappings":";AACA;;wBAEwB;;;AAGxB,WAAW,cAAc,EAAE,eAAe,EAAE,yBAAyB;EACnE,aAAa;AACf;;AAEA,gBAAgB,UAAU,CAAC;;AAE3B;EACE,4BAA4B;CAC7B,mGAAmG;IAChG,aAAa;IACb,2BAA2B;CAC9B;;;CAGA;CACA,cAAc;CACd;;EAEC;IACE,cAAc;IACd;;IAEA;CACH,qBAAqB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB,CAAC;CACzB,kBAAkB,gBAAgB,CAAC","sourcesContent":["\n/*======================\n    404 page\n=======================*/\n\n\n.page_404{ padding:40px 0; background:#fff; font-family: unset, serif;\n  height: 100vh;\n}\n\n.page_404  img{ width:100%;}\n\n.four_zero_four_bg{\n  background-repeat: no-repeat;\n background-image: url(https://i.pinimg.com/originals/0e/c0/db/0ec0dbf1e9a008acb9955d3246970e15.gif);\n    height: 600px;\n    background-position: center;\n }\n\n\n .four_zero_four_bg h1{\n font-size:80px;\n }\n\n  .four_zero_four_bg h3{\n\t\t\t font-size:80px;\n\t\t\t }\n\n\t\t\t .link_404{\n\tcolor: #fff!important;\n    padding: 10px 20px;\n    background: #007bff;\n    margin: 20px 5px;\n    border-radius: 5px;\n    display: inline-block;}\n\t.contant_box_404{ margin-top:-50px;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
