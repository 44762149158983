import { ToastrService } from 'ngx-toastr';
import {
  AffiliateMinimizedModel,
  AffiliatesInvitationStatusEnum,
} from './../../../../models/owner-dashboard';
import { SharedAffiliateService } from './../../../../components/user-dashboard/affiliate/shared-affiliate.service';
import { Router } from '@angular/router';
import { SharedOfferService } from './../../../../components/user-dashboard/offer/shared-offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { SortingTypeModel, FilteringTypeModel } from 'src/app/models/portal';

import {
  trigger,
  transition,
  style,
  state,
  animate,
} from '@angular/animations';
import { FunnelItem } from 'src/app/models/funnels';
import {
  AffiliateLinkMinimizedModel,
  AffiliateLinkStatusEnum,
} from 'src/app/models/owner-dashboard';

export enum OffersListScopeEnum {
  all = 'all',
  portal = 'portal',
  product = 'product',
  custom = 'custom',
}

@Component({
  selector: 'app-affiliates-list',
  templateUrl: './affiliates-list.component.html',
  styleUrls: ['./affiliates-list.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('shrinkOut', [
      state('in', style({})),
      transition('* => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('void => true', [
        style({ transform: 'translateX(-150px)', opacity: 0 }),
        animate(400, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkOutOnlyIn', [
      state('in', style({})),
      transition('void => *', [
        style({ transform: 'translateX(250px)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AffiliatesListComponent implements OnInit {
  @Input() affiliatesList: AffiliateMinimizedModel[] = [];
  @Input() loaded = false;

  affiliatesInvitationStatusEnum = AffiliatesInvitationStatusEnum;
  // Pagination
  currentPage = 1;
  startIndex = 0;
  numberInPage = 6;
  endIndex = 6;

  // Sort And Filtering Features
  searchWord: string = '';

  sortObject: SortingTypeModel = {
    index: 0,
    displayName: 'Newest By Joining',
    column: 'joinedAt',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    {
      index: 0,
      displayName: 'Newest By Joining',
      column: 'joinedAt',
      order: 'dsc',
    },
    {
      index: 1,
      displayName: 'Oldest By Joining',
      column: 'joinedAt',
      order: 'asc',
    },
    {
      index: 2,
      displayName: 'Newest By Invitation',
      column: 'invitedAt',
      order: 'dsc',
    },
    {
      index: 3,
      displayName: 'Oldest By Invitation',
      column: 'invitedAt',
      order: 'asc',
    },
    {
      index: 4,
      displayName: 'Recently Updated',
      column: 'updatedAt',
      order: 'dsc',
    },
    { index: 5, displayName: 'A-Z Title', column: 'firstName', order: 'asc' },
    { index: 6, displayName: 'Z-A Title', column: 'firstName', order: 'dsc' },
  ];

  allSorting: SortingTypeModel[] = [
    {
      index: 4,
      displayName: 'Recently Updated',
      column: 'updatedAt',
      order: 'dsc',
    },
    { index: 5, displayName: 'A-Z Title', column: 'firstName', order: 'asc' },
    { index: 6, displayName: 'Z-A Title', column: 'firstName', order: 'dsc' },
  ];

  joinedSorting: SortingTypeModel[] = [
    {
      index: 0,
      displayName: 'Newest By Joining',
      column: 'joinedAt',
      order: 'dsc',
    },
    {
      index: 1,
      displayName: 'Oldest By Joining',
      column: 'joinedAt',
      order: 'asc',
    },
  ];
  pendingSorting: SortingTypeModel[] = [
    {
      index: 2,
      displayName: 'Newest By Invitation',
      column: 'invitedAt',
      order: 'dsc',
    },
    {
      index: 3,
      displayName: 'Oldest By Invitation',
      column: 'invitedAt',
      order: 'asc',
    },
  ];

  filterObject: FilteringTypeModel = {
    index: 1,
    displayName: 'Joined',
    column: 'status',
    value: AffiliatesInvitationStatusEnum.joined,
  };
  filterTypes: FilteringTypeModel[] = [
    { index: 0, displayName: 'All', column: '', value: '' },
    {
      index: 1,
      displayName: 'Joined',
      column: 'status',
      value: AffiliatesInvitationStatusEnum.joined,
    },
    {
      index: 2,
      displayName: 'Pending',
      column: 'status',
      value: AffiliatesInvitationStatusEnum.pending,
    },
  ];

  customFilter: FilteringTypeModel = {
    index: 3,
    displayName: 'Rejected',
    column: 'status',
    value: AffiliatesInvitationStatusEnum.rejected,
  };

  constructor(
    public sharedAffiliateService: SharedAffiliateService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    // this.offersList = await this.sharedOfferService.getOffers();
  }
  // Sort And Filtering Features
  sortTypeChange(sortObject: SortingTypeModel) {
    this.sortObject = sortObject;
  }

  resetPagination() {
    this.startIndex = 0;
    this.endIndex = this.numberInPage;
    this.currentPage = 1;
  }
  filterTypeChange(filterObject: FilteringTypeModel) {
    this.filterObject = filterObject;
    this.sortTypes = [];
    this.sortTypes.unshift(...this.allSorting);
    switch (filterObject.value) {
      case AffiliatesInvitationStatusEnum.joined:
        this.sortTypes.unshift(...this.joinedSorting);
        break;
      case AffiliatesInvitationStatusEnum.pending:
        this.sortTypes.unshift(...this.pendingSorting);
        break;
      case AffiliatesInvitationStatusEnum.rejected:
        this.sortTypes.unshift(...this.pendingSorting);
        break;
      default:
        this.sortTypes.unshift(...this.joinedSorting);
        this.sortTypes.unshift(...this.pendingSorting);
        break;
    }
    this.sortObject = this.sortTypes[0];
    this.resetPagination();
  }

  viewAffiliate(id: number) {
    this.router.navigate([
      'dashboard',
      'affiliate',
      'my-affiliates',
      id,
      'view',
    ]);
  }

  async sendMessageToAffiliate(affiliate: AffiliateMinimizedModel) {
    this.sharedAffiliateService.goToThread(
      affiliate.messengerThreadId,
      affiliate.id
    );
    // this.toastr.success('Send Message');
  }

  async resendInvitationToAffiliate(email: string) {
    await this.sharedAffiliateService.resendInvitation(email);
    this.toastr.success('Invitation sent successfully');
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  consoleHandler(data: any) {}

  // Pagination
  getArrayLenght(length) {
    return new Array(this.getPagesNumber(length));
  }

  getPagesNumber(length) {
    return Math.ceil(length / this.numberInPage);
  }

  getIndex(pageIndex) {
    if (pageIndex > 0) {
      this.currentPage = pageIndex;
      this.startIndex = (pageIndex - 1) * this.numberInPage;
      this.endIndex = this.startIndex + this.numberInPage;
    }
  }
}
