import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentProcessType } from 'src/app/components/register/register.component';
import { ProcessingStatusEnum } from 'src/app/shared/partials/stripepaymentsimple/stripepaymentsimple.component';

@Component({
  selector: 'app-add-payment-method-modal',
  templateUrl: './add-payment-method-modal.component.html',
  styleUrls: ['./add-payment-method-modal.component.css']
})
export class AddPaymentMethodModalComponent implements OnInit {
  processingStatusEnum = ProcessingStatusEnum;
  paymentErrorMessage: string;
  loaded: boolean = false;
  processing:ProcessingStatusEnum = ProcessingStatusEnum.none;
  payEmit: EventEmitter<{
    bundleID: number;
    paymentProcess: PaymentProcessType;
    pid?: string;
  }> = new EventEmitter();

  @Input('opener') set modalOpener(value: boolean) {
    if (!!value) {
      this.open();
    }
  }

  @Output() openerChange = new EventEmitter<boolean>();

  @ViewChild('mymodal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  constructor(private modalService: NgbModal, private sharedProfileService: SharedProfileService) {}

  ngOnInit() {}

  closeModal() {
    this.modalRef.dismiss();
    this.openerChange.emit(false);
  }
  // Affiliate Links List

  // Modal Open
  async open(content?) {
    this.onOpenModal();
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      centered: true,
      animation: true,
      windowClass: 'global-modal-class modal-animate-in payment-modal',
    });
  }

  resetData() {
    this.processing = ProcessingStatusEnum.none;
    this.paymentErrorMessage = null;
  }
  async onOpenModal() {
    this.resetData()
  }

  stripeAddNewCard() {
    this.payEmit.emit({
      bundleID: undefined,
      paymentProcess: PaymentProcessType.save_only,
    });
  }

  onLoadDone() {
    this.loaded = true;
  }

  onStartPayment() {
    this.processing = ProcessingStatusEnum.processing;
  }

  onErrorPayment(errorMessage: any) {
    if (!!errorMessage) {
    this.paymentErrorMessage = errorMessage;
    }
    this.processing = ProcessingStatusEnum.error;
  }

  async onFinishPayment() {
    this.processing = ProcessingStatusEnum.success;
    await this.sharedProfileService.getAllPaymentMethodsForUser();
    setTimeout(() => {
      this.closeModal();
    }, 1500);
  }

}
