import { AiService } from '../../services/ai.service';
import { SharedPortalViewService } from './../../../components/user-dashboard/portal/view/shared-portal-view.service';
import { colorSet } from './../../../models/global-classes';
import { Component, Input, EventEmitter, Output } from '@angular/core';

export class ButtonItem {
  colorSet: colorSet = 'primary'; colorSetHover?: colorSet = 'primary';
}
@Component({
  selector: 'app-dynamic-ai-button',
  templateUrl: './dynamic-ai-button.component.html',
  styleUrls: ['./dynamic-ai-button.component.css']
})
export class DynamicAiButtonComponent {
  @Input() smartButton: boolean = false;
  @Input() delay: number = 500;
  loading: boolean = false;
  @Input() iconButton: boolean = false;
  @Input() buttonLabel: string = 'Use Learmo AI';
  @Input() iconSvg: string = 'assets/images/svg/system/ai.svg?rand=1.2';
  @Input() buttonSettings: ButtonItem = {
    colorSet: 'primary',
  };

  @Input() size: 'lg' | 'sm' | '';
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>()
  constructor(public ai: AiService) {

  }

  buttonClicked() {
    this.loading = true;
    setTimeout(() => {
      this.buttonClick.emit();
      setTimeout(() => {
        this.loading = false;
      }, this.delay);

    }, this.delay);

  }

  // @Input() buttonsList: ButtonItem[] = []
}
