export const environment = {
  production: false,
  name: 'dev',
  baseURL:
    'https://learmo.dev/',
  basePath: '/',
  registerPath: 'network/join/',
  oldFramework: {
    backofficeUrl: 'http://www.lifestylegalaxy.zen/members.php',
    visitorUrl: 'http://www.lifestylegalaxy.zen',
  },
  api: {
    url: 'https://api.learmo.dev/api/v1',
    socketUrl: 'wss://api.learmo.dev:8080/',
    subPath: '',
  },
  gleap: {
    projectKey: 'LrDsj4j7xuSAcoSXIIfEHfmohl1AFJcM'
  },
  appwrite: {
    url: 'https://auth.learmo.dev/v1',
    projectKey: '659d58b114b0b6814e58',
    verificationRedirectURL: 'https://learmo.dev/verify/email',
    oAuthCallbackUrl: {
      magicLink: 'https://learmo.dev/auth/magic-callback',
      google: 'https://learmo.dev/auth/callback',
    },
    resetPasswordCallbackUrl: 'https://learmo.dev/verify/reset-password',
    oAuthFailUrl: 'https://learmo.dev/auth/login',
    oAuthEnabled: true
  },
  analytics: {
    plausible: {
      analyticsEnabled: true,
      jsSource: 'https://analytics.learmo.dev/js/script.js',
      dataDomain: 'learmo.dev',
    }
  },
  stripeSecret:
    'pk_test_51Ihzu3BfQfhFkH5JxaoPX15sIv2MJ5KNSEiFxnQYx35H3VSgGsHxp88hGqmUEMitqEKYVrdkRh04hoP44KNHHi9i00lpprtgsL',
  btcPayServerURL: 'https://btcpay.learmo.dev',
  btcPayRedirectPOSTIntegrationURL: 'portal/user/btcpay-redirect',
  disableRegister: false,
  storageKeys: {
    currentUserStorageKey : 'currentUser',
    appwriteProfileStorageKey : 'appwriteProfile',
    tokenStorageKey :'token',
    paypalIntegration: 'paypalConfig'
  },
  appSettings: {
    errors: {
      displayPopAlert: false
    }
  }
  
};
