import { ApiService } from './../../../../services/lsg.api.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';

import { Store } from '@ngrx/store';


import {findCountryByCodeOrName} from '../../../../data/countries.data'
import { getWebsiteMainAnalyticsActions } from './website-analytics.actions';

@Injectable()
export class WebsiteAnalyticsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store
  ) { }

 



  // Analytics
  loadWebsiteAggregateAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWebsiteMainAnalyticsActions.load),
      mergeMap(() =>
        forkJoin({
          aggregateAnalytics: this.api.getWebsiteAggregateAnalytics(),
          bestCountry: this.api.getWebsiteBestTrafficCountry()
        })
          .pipe(
            map((response: { aggregateAnalytics: {results: any}, bestCountry: { results: {country: string, visitors: number}[] } }) => {
              console.log(response);
              const bestCountry = !!response.bestCountry?.results?.length ? {...response.bestCountry.results[0], countryItem: findCountryByCodeOrName(response.bestCountry.results[0].country)} : undefined
              return getWebsiteMainAnalyticsActions.loadSuccess({ stats: {...response.aggregateAnalytics.results, bestCountry} })
            }
            ),
            catchError((error) =>
              of(getWebsiteMainAnalyticsActions.loadFailure({ error }))
            )
          )
      )
    )
  );
}
