import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';
import { lastValueFrom, takeWhile } from 'rxjs';
import { ApiService } from '../../shared/services/lsg.api.service';
import { Injectable } from '@angular/core';
import { UpdateProfileRequest, User } from 'src/app/models';
import { TopLevelNavMenuItem } from 'src/app/shared/partials/dynamic-top-level-nav/dynamic-top-level-nav.component';
import { AuthenticationService } from 'src/app/shared/services';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentMethodModel } from 'src/app/models/payment.model';
import {
  DeviceTypeEnum,
  ActiveSessionItemModel,
  OperatingSystemEnum,
  BrowserNameEnum,
} from './security-settings/active-sessions-section/active-session-item/active-session-item.component';
import { SubscriptionBillingInfo } from 'src/app/models/subscription';
import { Store } from '@ngrx/store';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';
import { Models } from 'appwrite';

import {userProfileActions } from 'src/app/shared/store/_features/user/profile/profile.actions';
import { isEmailChanged, selectUserState } from 'src/app/shared/store/_features/user/user.selector';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SharedProfileService {
  subscriptionInfo: SubscriptionBillingInfo;
  activeSessionsData: ActiveSessionItemModel[] = [];
  PaymentMethodsData: PaymentMethodModel[] = [];
  passwordChanged: boolean = false;
  // emailChanged: boolean = false;
  emailSent: boolean = false;

  loaded: boolean = false;
  profileNavigationMenuItems: TopLevelNavMenuItem[] = [];

  isEmailConfirmed: boolean = false;
  isEmailChanged: boolean = false;
  currentUser: User;
  appwriteProfile: Models.User<Models.Preferences>;
  processing: boolean = false;
  constructor(
    private authenticationService: AuthenticationService,
    private appwriteService: AppwriteService,
    private api: ApiService,
    private store: Store,
    public spinService: ProgressSpinnerService,
    private userDashboardService: UserDashboardService,
    private toastr: ToastrService
  ) {
    this.logAppState()
    this.authenticationService.onLogout.subscribe((value: boolean) => {
      this.spinService.showLoader('Logging Out');
      // this.store.dispatch(logout());
      setTimeout(() => {
        location.reload();
      }, 2500);

    });

    this.authenticationService.reloadMyProfile();
    this.authenticationService.currentUser.subscribe((learmoProfile: User) => {

      this.currentUser = {...learmoProfile};
    });

    this.store.select(isEmailChanged).subscribe((isEmailChanged: boolean) => {
      this.isEmailChanged = isEmailChanged;
    });

    this.authenticationService.appwriteProfile.subscribe((value: Models.User<Models.Preferences>) => {
      this.appwriteProfile = value;
      if (!!value?.prefs && !!value?.prefs['theme']) {
        this.toggleDarkMode(value.prefs['theme'])
      }
      else {
        this.toggleDarkMode('light')
      }
    });
  }


  // onUserEmailChange(newEmail: string) {
  //   this.store.dispatch(updateUserProfileSuccess({ learmoProfile: { email: newEmail } }));
  // }
  logAppState() {
    this.store.select(state => state).subscribe(fullState => {

    });
  }

  toggleDarkMode(theme: 'light' | 'dark' = undefined) {
    let mode = this.userDashboardService.toggleDarkMode(theme);
    if (mode == 'light' || mode == 'dark')
      this.setModeAsDefault(mode);
  }

  setModeAsDefault(mode: 'light' | 'dark' = 'light') {
    this.appwriteService.setPreference('theme', mode).subscribe();
  }

  async updateProfile(
    payload: UpdateProfileRequest,
    errorHandler: any = undefined
  ) {
    this.store.dispatch(userProfileActions.update({ payload }));
    this.processing = true;
    this.store.select(selectUserState).pipe(takeWhile(state => state?.profileUpdateProcess.status !== 'done' && state?.profileUpdateProcess.status !== 'error', true)).subscribe(state=> {
      switch (state?.profileUpdateProcess.status) {
        case 'done':
          this.onUpdateProfileDone();
          break;
        case 'error':
          this.onUpdateProfileError(state.profileUpdateProcess.error)
        break;
        default:
          break;
      }

    })
    // let request$ = this.api.updateProfile(payload, errorHandler);

    // let response = await lastValueFrom(request$);

    // if (!!payload?.email && payload?.email != response?.email) {
    //   this.emailChanged = true;
    // }
  }

  onUpdateProfileDone() {
    this.processing = false;
    this.toastr.success('Profile Updated Successfully', 'Done!');

  }

  onUpdateProfileError = (errorMessage: string) => {
    this.processing = false;
    this.toastr.success(errorMessage, 'Error!');
  }

  scrollToVerification() {
    let sectionId = 'announcementArea';
    this.scrollTo(sectionId, 'center');
    setTimeout(() => {
      this.noticeSectionByID(sectionId);
    }, 500);
  }

  noticeSectionByID(elementID: string) {
    const noticeClasses = ['focus'];

    noticeClasses.forEach((className) => {
      (document.getElementById(elementID) as HTMLElement).classList.add(
        className
      );

      setTimeout(() => {
        (document.getElementById(elementID) as HTMLElement).classList.remove(
          className
        );
      }, 1500);
    });
  }

  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  async getActiveSessions() {
    let response = [
      {
        id: 1,
        country: {
          id: 35,
          code: 'EG',
          name: 'Egypt',
          flag: null,
        },
        state: {
          name: 'Ad Daqahlīyah',
          code: 'AD',
          id: 1,
        },
        deviceType: DeviceTypeEnum.computer,
        browserName: BrowserNameEnum.Edge,
        browserVersion: '5.555.333.2',
        ipAddress: '41.55.21.33',
        os: OperatingSystemEnum.Windows,
        lastActivityTime: 1696404517,
        loginTime: 1696404517,
        isActive: true,
        isCurrentSession: true,
      },
    ];

    this.activeSessionsData = response;
    return response;
  }
  async getCountries() {
    let request$ = this.api.getCountries(this.handleGetCountriesError);

    let countries = await lastValueFrom(request$);

    return countries;
  }

  async getStates(countryId: number) {
    let request$ = this.api.getStates(countryId, this.handleGetStatesError);

    let states = await lastValueFrom(request$);

    return states;
  }




  async getSubscriptionInfo() {
    let request$ = this.api
      .getSubscriptionBundleInfo();

    let response = await lastValueFrom(request$);
    this.subscriptionInfo = response;
  }


  // Payments
  async getAllPaymentMethodsForUser(sorted: boolean = true) {
    const request$ = await this.api.getAllPaymentMethod(
      sorted,
      this.handleGetPaymentMethodsError
    );
    const response: PaymentMethodModel[] = await lastValueFrom(request$);
    this.PaymentMethodsData = response;
    return response;
  }

  async setDefaultPaymentMethod(id: string) {
    const request$ = this.api.setDefaultPayment(
      id,
      this.handleSetDefaultPaymentMethodsError
    );

    let response: { success: boolean } = await lastValueFrom(request$);
  }

  async deletePaymentMethod(id: any) {
    let request$ = this.api.deletePaymentMethod(
      id,
      this.deletePaymentMethodsError
    );

    let response = await lastValueFrom(request$);

    return response;
  }

  private deletePaymentMethodsError(error: any) {}

  private handleSetDefaultPaymentMethodsError = (error: any) => {};

  private handleGetPaymentMethodsError = (error: any) => {};

  handleGetCountriesError = (error: HttpErrorResponse) => {};
  handleGetStatesError = (error: HttpErrorResponse) => {};
}
