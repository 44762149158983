import { Subscription, lastValueFrom } from 'rxjs';
import { Plan } from 'src/app/models/plans';
import {
  ChangeDetectorRef,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  StripeCardCvcElement,
  StripeCardElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js';
import { Country } from 'src/app/models/country';
import { ApiService } from '../../../shared/services/lsg.api.service';
import { PaymentService } from '../../../shared/services/payment.service';
import { FormControl, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { PaymentProcessType } from '../../../components/register/register.component';
import Swal from 'sweetalert2';
import { ProgressSpinnerService } from '../../../shared/services/progress-spinner.service';
import { AuthenticationService } from '../../../shared/services';
import { HolderNameRegex } from '../../services/regex';

export enum ProcessingStatusEnum {
  none = 'none',
  processing = 'processing',
  success = 'success',
  error = 'error',
}

export enum StripeValidationErrorTypeEnum {
  // Card Number Errors
  card_number = 'card_number',
  card_expiry = 'card_expiry',
  card_cvc = 'card_cvc'
}
@Component({
  selector: 'app-stripepaymentsimple',
  templateUrl: './stripepaymentsimple.component.html',
  styleUrls: ['./stripepaymentsimple.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ 'max-height': '0px', minHeight: '0', opacity: 0 }),
        animate('0.4s ease-out', style({ 'max-height': '200px', opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripepaymentSimpleComponent implements OnInit, OnDestroy {
  processingStatusEnum = ProcessingStatusEnum
  stripeValidationErrorTypeEnum = StripeValidationErrorTypeEnum;
  validationErrorType: StripeValidationErrorTypeEnum;
  validationErrorMessage: string;

  cardNumberErrors: string[] = ['incomplete_number', 'invalid_number']
  cardExpiryErrors: string[] = ['incomplete_expiry', 'invalid_expiry_year_past', 'invalid_expiry_month', 'invalid_expiry_year', 'expired_card']
  cardCVCErrors: string[] = ['incomplete_cvc', 'incorrect_cvc', 'invalid_cvc']


  loaded = false;
  processing: ProcessingStatusEnum = ProcessingStatusEnum.none;

  clientSecret: string;

  cardHolderElement = new FormControl('', [
    Validators.required,
    Validators.pattern(HolderNameRegex),
  ]);
  // cardHolderError: boolean;
  cardElement: StripeCardNumberElement;
  cardExpiryElement: StripeCardExpiryElement;
  cardCVCElement: StripeCardCvcElement;
  error: string;

  // startLoading = true;
  cardNumber: any;

  cardNumberStatus: boolean;
  cardExpiryStatus: boolean;
  cardCVCStatus: boolean;

  cardFieldsStatus: { number: boolean; expiry: boolean; cvc: boolean } = {
    number: false,
    expiry: false,
    cvc: false,
  };

  @Input() payNow: EventEmitter<{
    bundleID: number;
    paymentProcess: PaymentProcessType;
    pid?: string;
  }>;

  payNowSubscription: Subscription;
  // @Input() cardHolderName: string = "";
  @Output() focusing = new EventEmitter();
  countries: Country[];
  billingExpand = false;
  iagreecard = false;

  isCardFlipped = false;
  creditCardType = 'visa';
  isPaymentValid = false;

  @Output() onLoadDone: EventEmitter<any> = new EventEmitter();

  @Output() onStartPayment = new EventEmitter();
  @Output() onFinishPayment = new EventEmitter();
  @Output() onErrorPayment: EventEmitter<string> = new EventEmitter();

  @Output() isPaymentValidEmitter: EventEmitter<boolean> = new EventEmitter();

  // @Output() onCardHolderNameFocus = new EventEmitter();

  bundleID: number = undefined;
  @Input() couponID = undefined;

  address: {
    city?: string;
    state?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
  } = {
    city: undefined,
    state: undefined,
    country: undefined,
    line1: undefined,
    line2: undefined,
    postal_code: undefined,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService
  ) {
    this.addPaymentMethodErrorHandler =
      this.addPaymentMethodErrorHandler.bind(this);
    this.handleGetCountriesError = this.handleGetCountriesError.bind(this);
    this.handleSubscribeUserError = this.handleSubscribeUserError.bind(this);
  }
  ngOnDestroy(): void {
    this.payNowSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.payNowSubscription = this.payNow?.subscribe(
      (a: {
        bundleID: number;
        paymentProcess: PaymentProcessType;
        pid?: string;
      }) => {
        this.bundleID = a.bundleID;
        this.submitPayment(a.paymentProcess, a.pid);
      }
    );

    this.getCountries();
    this.loadStripeCard();
  }

  async onCountryChanged(value: string) {}

  private async getCountries() {
    this.countries = await this.api
      .getCountries(this.handleGetCountriesError)
      .toPromise();
  }

  handleGetCountriesError(error: HttpErrorResponse) {}

  getCardType() {
    let number = this.cardNumber;
    let re = new RegExp('^4');

    if (number.match(re) != null) return (this.creditCardType = 'visa');

    re = new RegExp('^(34|37)');
    if (number.match(re) != null) return (this.creditCardType = 'amex');

    re = new RegExp('^5[1-5]');
    if (number.match(re) != null) return (this.creditCardType = 'mastercard');

    re = new RegExp('^6011');
    if (number.match(re) != null) return (this.creditCardType = 'discover');

    re = new RegExp('^9792');
    if (number.match(re) != null) return (this.creditCardType = 'troy');

    return (this.creditCardType = 'unknown'); // default type
  }

  async submitPayment(
    paymentProcess: PaymentProcessType,
    pid: string = undefined
  ) {
    switch (paymentProcess) {
      case PaymentProcessType.save_only:
        await this.saveCard(false);
        break;
      case PaymentProcessType.save_and_register:
        await this.saveCard();
        break;
      case PaymentProcessType.just_pay:
        await this.payWithCard(pid);

        break;
      case PaymentProcessType.pay_and_save:
        await this.saveCardAndPay();
        break;
      default:
        break;
    }
  }

  async saveCard(register: boolean = false) {
    // this.cardHolderElement.markAsTouched();
    this.onStartPayment.emit();
    this.processing = ProcessingStatusEnum.processing;
    // console.warn(this.bundleID);

    let request$ =  this.api
      .addPaymentMethod(
        this.bundleID,
        this.couponID,
        register,
        this.addPaymentMethodErrorHandler
      )
      const reponse : { clientSecret: string } = await lastValueFrom(request$)
    this.clientSecret = reponse.clientSecret;
    const { setupIntent, error } =
      await this.paymentService.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          billing_details: { name: this.cardHolderElement.value },
          card: this.cardElement,
        },
      });

    if (!!error || !!setupIntent.last_setup_error) {
      
      if (error.type == 'validation_error') {
        if (this.cardNumberErrors.includes(error.code)) {
          this.validationErrorType = StripeValidationErrorTypeEnum.card_number
        }
        else if (this.cardExpiryErrors.includes(error.code)) {
          this.validationErrorType = StripeValidationErrorTypeEnum.card_expiry
        }
        else if (this.cardCVCErrors.includes(error.code)) {
          this.validationErrorType = StripeValidationErrorTypeEnum.card_cvc

        }
        this.validationErrorMessage = error.message;
        this.onErrorPayment.emit(null);
      }
      else {

      if (!!error) {
        this.onErrorPayment.emit(error.message);
        return false;
      }
      if (!!setupIntent.last_setup_error?.message) {
        this.onErrorPayment.emit(setupIntent.last_setup_error.message);
        return false;
      }
    }
    } else {
      await this.delay(3000);
      this.onFinishPayment.emit();
      setTimeout(() => {
        this.cardElement.clear();
      }, 5000);
    }
    this.processing = ProcessingStatusEnum.success;
  }


  async saveCardAndPay() {
    // this.cardHolderElement.markAsTouched();
    this.onStartPayment.emit();
    this.processing = ProcessingStatusEnum.processing;
    // console.warn(this.bundleID);
    const addPaymentMethodResult: { clientSecret: string } = await this.api
      .addPaymentMethod(
        this.bundleID,
        this.couponID,
        null,
        this.addPaymentMethodErrorHandler
      )
      .toPromise();

    this.clientSecret = addPaymentMethodResult.clientSecret;
    await this.paymentService.stripe
      .confirmCardSetup(this.clientSecret, {
        payment_method: { card: this.cardElement },
      })
      .then(async ({ setupIntent, error }) => {
        if (!!error) {
          this.onErrorPayment.emit(error.message);
        } else {
          await this.delay(3000);
          // this.onFinishPayment.emit();
          // await this.payWithCard(setupIntent.payment_method);
          setTimeout(() => {
            this.cardElement.clear();
          }, 5000);
          // this.onFinishPayment.emit();
        }
        this.processing = ProcessingStatusEnum.success;
      });
  }

  async errorSubscription() {
    this.processing = ProcessingStatusEnum.error;
    Swal.fire({
      title: 'Something Went Wrong!',
      text: 'Error',
      icon: 'error',
    });
  }
  async doneSubscription() {
    this.onFinishPayment.emit();
    this.processing = ProcessingStatusEnum.success;
  }

  handleSubscribeUserError(error: any) {
    this.onErrorPayment.emit(error.message);
  }

  async payWithCard(pid: string, typeOfPayment: 'main' | 'sub' = 'main') {
    this.onStartPayment.emit();
    this.processing = ProcessingStatusEnum.processing;
    // console.warn(this.bundleID);
    // await this.paymentService.subscriptionStripe(this.userBundle.id, pid).toPromise();
    const subscribe: { clientSecret: string } = await this.paymentService
      .subscriptionStripe(
        this.bundleID,
        pid,
        undefined,
        this.handleSubscribeUserError
      )
      .toPromise();
    await this.paymentService.loadStripe();
    // const { error, paymentIntent } =
    await this.paymentService.stripe
      .confirmCardPayment(subscribe.clientSecret)
      .then(async (result) => {
        if (!!result.error || !!result.paymentIntent.last_payment_error) {
          this.authenticationService.forget();

          if (!!result.error) {
            this.onErrorPayment.emit(result.error.message);
            return false;
          }
          if (!!result.paymentIntent.last_payment_error?.message) {
            this.onErrorPayment.emit(
              result.paymentIntent.last_payment_error.message
            );
            return false;
          }
        } else {
          await this.delay(3000);
          this.onFinishPayment.emit();
        }
      });
  }

  // onCardHolderFocus() {
  //   this.onCardHolderNameFocus.emit();
  // }

  async loadStripeCard() {
    // this.startLoading();
    await this.paymentService.loadStripe();
    const elements = this.paymentService.stripe.elements();

    // Card Number Element
    this.cardElement = elements.create('cardNumber', {
      showIcon: true,
      placeholder: 'Card number',
      classes: {
        invalid: 'is-invalid',
      },

      style: {
        base: {
          fontSize: '1.1rem',
          fontWeight: 500,
          color: '#5E6278',
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
      },
    });

    await this.cardElement.mount('#cardNumber');
    let validationCounter = 0;
    this.cardElement.on('change', (event) => {
      this.focusing.emit();

      this.creditCardType = event.brand;
      this.cardNumberStatus = event.complete;
      if (!!this.cardNumberStatus) {
        this.cardExpiryElement.focus();
      }
      if (
        !!this.cardCVCStatus &&
        !!this.cardExpiryStatus &&
        !!this.cardNumberStatus
      ) {
        this.isPaymentValidEmitter.emit(true);
      } else {
        this.isPaymentValidEmitter.emit(false);
      }
      this.cdr.markForCheck();
    });

    this.cardElement.on('focus', () => {
      this.cardFieldsStatus.number = true;
      this.cdr.markForCheck();
    });

    this.cardElement.on('blur', () => {
      this.cardFieldsStatus.number = false;
      this.cdr.markForCheck();
    });

    // Card CVC Element
    this.cardCVCElement = elements.create('cardCvc', {
      classes: {
        invalid: 'is-invalid',
      },
      style: {
        base: {
          fontSize: '1.1rem',
          fontWeight: 500,
          color: '#5E6278',
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
      },
    });
    await this.cardCVCElement.mount('#cardCvc');
    this.cardCVCElement.on('focus', () => {
      this.cardFieldsStatus.cvc = true;
      this.isCardFlipped = true;
      this.cdr.markForCheck();
    });

    this.cardCVCElement.on('blur', () => {
      this.cardFieldsStatus.cvc = false;
      this.isCardFlipped = false;
      this.cdr.markForCheck();
    });

    this.cardCVCElement.on('change', (event) => {
      this.focusing.emit();
      this.cardCVCStatus = event.complete;
      if (!!this.cardCVCStatus) {
        this.cardCVCElement.blur();
      }
      if (
        !!this.cardCVCStatus &&
        !!this.cardExpiryStatus &&
        !!this.cardNumberStatus
      ) {
        this.isPaymentValidEmitter.emit(true);
      } else {
        this.isPaymentValidEmitter.emit(false);
      }
      this.cdr.markForCheck();
    });

    // Card Expiry Element
    this.cardExpiryElement = elements.create('cardExpiry', {
      classes: {
        invalid: 'is-invalid'
      },
      style: {
        base: {
          fontSize: '1.1rem',
          fontWeight: 500,
          color: '#5E6278',
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
      },
    });
    await this.cardExpiryElement.mount('#cardExpiry');
    this.cardExpiryElement.on('change', (event) => {
      this.focusing.emit();
      this.cardExpiryStatus = event.complete;
      if (!!this.cardExpiryStatus) {
        this.cardCVCElement.focus();
      }
      if (
        !!this.cardCVCStatus &&
        !!this.cardExpiryStatus &&
        !!this.cardNumberStatus
      ) {
        this.isPaymentValidEmitter.emit(true);
      } else {
        this.isPaymentValidEmitter.emit(false);
      }
      this.cdr.markForCheck();
    });

    this.cardExpiryElement.on('focus', () => {
      this.cardFieldsStatus.expiry = true;
      this.cdr.markForCheck();
    });

    this.cardExpiryElement.on('blur', () => {
      this.cardFieldsStatus.expiry = false;
      this.cdr.markForCheck();
    });

    // Loading Statement
    let loadingStepper = 0;
    this.cardExpiryElement.on('ready', () => {
      loadingStepper++;
      if (loadingStepper === 3) {
        this.loaded = true;
        this.cdr.markForCheck();
        this.onLoadDone.emit();
      }
    });

    this.cardElement.on('ready', () => {
      loadingStepper++;

      if (loadingStepper === 3) {
        this.loaded = true;
        this.cdr.markForCheck();
        this.onLoadDone.emit();
      }
    });

    this.cardCVCElement.on('ready', () => {
      loadingStepper++;

      if (loadingStepper === 3) {
        this.loaded = true;
        this.cdr.markForCheck();
        this.onLoadDone.emit();
      }
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  addPaymentMethodErrorHandler(error: any) {}
}
