import { ItemHeadSkeletonComponent } from './partials/skeletons/item-head-skeleton/item-head-skeleton.component';
import { GlobalRadioInfoCardComponent } from './partials/global-fields/global-radio-info-card/global-radio-info-card.component';


import { OfferListMinimalItemComponent } from './partials/main-lists/offers-list/offer-list-minimal-item/offer-list-minimal-item.component';
import { BtcpayIntegrationFormComponent } from '../components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay/btcpay-integration-form/btcpay-integration-form.component';
import { StripeIntegrationFromComponent } from './../components/user-dashboard/portal/view/portal-payment/integrate/method-form/stripe/stripe-integration-from/stripe-integration-from.component';
import { PaypalIntegrationListenerComponent } from './../components/user-dashboard/portal/view/portal-payment/integrate/paypal-integration-listener/paypal-integration-listener.component';
import { AiCategorySelectComponent } from './partials/ai/ai-idea-generator/ai-category-select/ai-category-select.component';
import { DynamicPostStatusComponent } from './partials/main-lists/blog-posts-list/blog-posts-list-item/dynamic-post-status/dynamic-post-status.component';
import { BlogPostsListItemComponent } from './partials/main-lists/blog-posts-list/blog-posts-list-item/blog-posts-list-item.component';
import { BlogPostsListComponent } from './partials/main-lists/blog-posts-list/blog-posts-list.component';
import { BrowserWebsiteViewComponent } from './partials/browser-website-view/browser-website-view.component';
import { MainNavBarListComponent } from './layout/owner-dashboard/main-nav-bar-list/main-nav-bar-list.component';
import { LearmoVisibleDirective } from './../directives/ui/learmo-element-visibility.directive';
import { ScrollSnapDirective } from './../directives/scroll-snap.directive';
import { UsersSymbolsGroupComponent } from './partials/users-symbols-group/users-symbols-group.component';
import { SortSelectedFirstPipe } from './pipes/sort-selected-first.pipe';
import { ContactsListItemComponent } from './partials/main-lists/contacts-list/contacts-list-item/contacts-list-item.component';
import { ContactsListComponent } from './partials/main-lists/contacts-list/contacts-list.component';
import { LearmoStickDirective } from './../directives/learmo-stick.directive';
import { StickybitsDirective } from './../directives/stickybit.directive';
import { LanguageSelectorComponent } from './partials/language-selector/language-selector.component';
import { IconsSelectorComponent } from './partials/icons-selector/icons-selector.component';
import { EditorToolbarComponent } from './partials/learmo-block-editor/editor-toolbar/editor-toolbar.component';
import { LearmoBlockEditorComponent } from './partials/learmo-block-editor/learmo-block-editor.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MediaLibraryStorageMeterComponent } from './partials/media-library/media-library-modal/media-library-storage-meter/media-library-storage-meter.component';
import { LoginMethodItemComponent } from './../components/auth/login-alt/user-login-form-alt/login-method-item/login-method-item.component';
import { ColorSelectComponent } from './../components/user-dashboard/portal/view/portal-identity/portal-identity-page/basic-information/color-select/color-select.component';
import { WaitListComponent } from './../components/wait-list/wait-list.component';

import { WaitListFormComponent } from './../components/wait-list/wait-list-form/wait-list-form.component';
import { SystemItemsSequenceComponent } from './partials/system-items-sequence/system-items-sequence.component';
import { OfferSelectorComponent } from './partials/global-fields/offer-selector/offer-selector.component';
import { GetProductLinkComponent } from './../components/user-dashboard/product/product-item/get-product-link/get-product-link.component';
import { ButtonViewComponent } from './partials/dynamic-preview-buttons/button-view/button-view.component';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { AccountSetupItemComponent } from './../components/user-dashboard/dashboard/setup-steps-widget/setup-steps/account-setup-item/account-setup-item.component';
import { SubscriptionStatusLabelComponent } from './../components/profile/subscription-and-billing-page/current-plan/subscription-status-label/subscription-status-label.component';
import { SubscriptionValidUntilComponent } from './../components/profile/subscription-and-billing-page/current-plan/subscription-valid-until/subscription-valid-until.component';
import { LearmoSubscriptionWidgetComponent } from './layout/owner-dashboard/learmo-subscription-widget/learmo-subscription-widget.component';
import { RefundListComponent } from './partials/main-lists/refund-list/refund-list.component';
import { RefundListItemComponent } from './partials/main-lists/refund-list/refund-list-item/refund-list-item.component';
import { WebsiteFinalizingComponent } from './layout/owner-dashboard/website-finalizing/website-finalizing.component';
import { SiteTemplateStepComponent } from './layout/owner-dashboard/account-setup-wizard/steps/site-template-step/site-template-step.component';
import { PortalIdentitySettingsComponent } from './../components/user-dashboard/portal/view/portal-overview/portal-identity-settings-modal/portal-identity-settings/portal-identity-settings.component';
import { PortalIdentityStepComponent } from './layout/owner-dashboard/account-setup-wizard/steps/portal-identity-step/portal-identity-step.component';
import { WelcomeOnboardingGalleryComponent } from './layout/owner-dashboard/account-setup-wizard/welcome-onboarding-gallery/welcome-onboarding-gallery.component';
import { SalesItemPopupComponent } from './../components/user-dashboard/owner-earnings/sales/sales-item/sales-item-popup/sales-item-popup.component';

import { SetupStepsComponent } from './../components/user-dashboard/dashboard/setup-steps-widget/setup-steps/setup-steps.component';
import { AccountStepsTrackingSidewidgetComponent } from '../components/user-dashboard/dashboard/setup-steps-widget/account-steps-tracking-sidewidget/account-steps-tracking-sidewidget.component';
import { SellingOnboardingGalleryComponent } from './partials/global-onboarding/galleries/selling-onboarding/selling-onboarding.component';



import { EarningsListComponent } from './partials/main-lists/earnings-list/earnings-list.component';
import { EarningsListItemComponent } from './partials/main-lists/earnings-list/earnings-list-item/earnings-list-item.component';
import { AddPaymentMethodModalComponent } from './../components/profile/payments/add-payment-method-modal/add-payment-method-modal.component';
import { PaymentMethodItemComponent } from './../components/profile/payments/payment-method-item/payment-method-item.component';
import { FullNamePipe } from './pipes/fullname.pipe';

import { FunnelOnboardingGalleryComponent } from './partials/global-onboarding/galleries/funnel-onboarding/funnel-onboarding.component';
import { FunnelCreateComponent } from '../components/user-dashboard/funnel/create/funnel-create/funnel-create.component';
import { FunnelListItemComponent } from './partials/main-lists/funnels-list/funnel-list-item/funnel-list-item.component';
import { MediaLibraryGridFileViewComponent } from './partials/media-library/media-library-modal/media-library-grid-file-view/media-library-grid-file-view.component';
import { MediaLibraryCropperComponent } from './partials/media-library/media-library-modal/media-library-upload-form/media-library-cropper/media-library-cropper.component';
import { MediaLibraryUploadFormComponent } from './partials/media-library/media-library-modal/media-library-upload-form/media-library-upload-form.component';
import { MediaLibraryModalComponent } from './partials/media-library/media-library-modal/media-library-modal.component';
import { MediaLibraryChangeZoneComponent } from './partials/media-library/media-library-change-zone/media-library-change-zone.component';
import { SystemLabelComponent } from './partials/publish-status-badges/system-label/system-label.component';
import { DynamicPageStatusComponent } from './partials/main-lists/pages-list/page-list-item/dynamic-page-status/dynamic-page-status.component';
import { PageListItemComponent } from './partials/main-lists/pages-list/page-list-item/page-list-item.component';
import { PagesListComponent } from './partials/main-lists/pages-list/pages-list.component';
import { ProductOnboardingGalleryComponent } from './partials/global-onboarding/galleries/product-onboarding/product-onboarding.component';
import { ListIsEmptyComponent } from './partials/main-lists/misc/list-is-empty/list-is-empty.component';
import { PasswordMeterComponent } from './../components/register/user-register-form/password-meter/password-meter.component';
import { UserRegisterFormComponent } from './../components/register/user-register-form/user-register-form.component';
import { MoneyInputComponent } from './partials/global-fields/money-input/money-input.component';
import { ProductSelectorComponent } from './partials/global-fields/product-selector/product-selector.component';
import { LearmoEditorComponent } from './partials/learmo-editor/learmo-editor.component';
import { TextareaAutoresizeDirective } from './../directives/textarea-autoresize.directive';
import { PaginationUnitComponent } from './partials/main-lists/lists-parts/pagination-unit/pagination-unit.component';
import { AiThinkingLoaderComponent } from './partials/ai/ai-thinking-loader/ai-thinking-loader.component';
import { AiOutlineCtaCardComponent } from './partials/ai/ai-outline-cta-card/ai-outline-cta-card.component';

import { AiCursorComponent } from './partials/ai/ai-cursor/ai-cursor.component';
import { DynamicItemsCardComponent } from './partials/dynamic-items-card/dynamic-items-card.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { MoneyModelPreviewComponent } from './partials/money-model-preview/money-model-preview.component';
import { ListThumbnailComponent } from './partials/main-lists/list-thumbnail/list-thumbnail.component';
import { LoadingListComponent } from './partials/main-lists/loading-list/loading-list.component';
import { LoadingListItemComponent } from './partials/main-lists/loading-list/loading-list-item/loading-list-item.component';
import { DynamicOfferStatusComponent } from './partials/main-lists/offers-list/offer-list-item/dynamic-offer-status/dynamic-offer-status.component';
import { DraftLabelComponent } from './../shared/partials/publish-status-badges/draft-label/draft-label.component';
import { PublishedLabelComponent } from './../shared/partials/publish-status-badges/published-label/published-label.component';

import { OfferListItemComponent } from './partials/main-lists/offers-list/offer-list-item/offer-list-item.component';
import { StudentProfileCardComponent } from './partials/global-popovers/student-profile-card/student-profile-card.component';
import { StudentListItemComponent } from './partials/main-lists/students-list/students-list-item/students-list-item.component';
import { StudentsListComponent } from './partials/main-lists/students-list/students-list.component';
import { AiCtaCardComponent } from './partials/ai/ai-cta-card/ai-cta-card.component';
import { AiIdeaGeneratorComponent } from './partials/ai/ai-idea-generator/ai-idea-generator.component';
import { DynamicAiButtonComponent } from './partials/dynamic-ai-button/dynamic-ai-button.component';
import { InputAutoFocusDirective } from './../directives/input-auto-focus.directive';
import { LearmoTargetComponent } from './layout/owner-dashboard/learmo-target/learmo-target.component';
import { LearmoRobotComponent } from './partials/learmo-robot/learmo-robot.component';
import { PricingInputComponent } from './partials/pricing-input/pricing-input.component';
import { PricingSliderComponent } from './partials/pricing-slider/pricing-slider.component';
import { ProductTypePreviewComponent } from './../components/user-dashboard/product/create/product-create-wizard/product-type-wizard-step/product-type-preview/product-type-preview.component';
import { DynamicActionButtonsComponent } from './partials/dynamic-preview-buttons/dynamic-action-buttons.component';
import { DynamicCarousellComponent } from './partials/dynamic-carousell/dynamic-carousell.component';
import { DynamicDropdownComponent } from './partials/dynamic-dropdown/dynamic-dropdown.component';
import { MediaLibraryViewOnlyComponent } from './partials/media-library/media-library-view-only/media-library-view-only.component';
import { DynamicSecondLevelNavComponent } from './partials/dynamic-second-level-nav/dynamic-second-level-nav.component';
import { ProductListItemComponent } from './partials/main-lists/products-list/product-list-item/product-list-item.component';
import { HorizontalScrollDirective } from './../directives/horizontal-scroll.directive';
import { MainNavBarComponent } from './layout/owner-dashboard/main-nav-bar/main-nav-bar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PointerGuideComponent } from './layout/owner-dashboard/pointer-guide/pointer-guide.component';
import { PortalIdentityTooltipComponent } from './../components/user-dashboard/portal/view/portal-view-page/portal-identity-tooltip/portal-identity-tooltip.component';
import { SystemPaymentMethodViewComponent } from './partials/system-payment-method-view/system-payment-method-view.component';
import { AddPortalCustomDomainComponent } from './../components/user-dashboard/portal/view/portal-domains/add-portal-custom-domain/add-portal-custom-domain.component';
import { EditPortalSubdomainComponent } from './../components/user-dashboard/portal/view/portal-domains/edit-portal-subdomain/edit-portal-subdomain.component';
import { PortalIdentitySettingsModalComponent } from './../components/user-dashboard/portal/view/portal-overview/portal-identity-settings-modal/portal-identity-settings-modal.component';
import { DomainPortalSettingsComponent } from './../components/user-dashboard/domains/domain-item/domain-portal-settings/domain-portal-settings.component';
import { DomainDetailsCardComponent } from './../components/user-dashboard/domains/domain-item/domain-details-card/domain-details-card.component';
import { DomainDetailsPopoverComponent } from './../components/user-dashboard/domains/domain-item/domain-details-popover/domain-details-popover.component';
import { CardLoaderDirectives } from './../directives/card-loader.directive';
import { OwnerAnnouncementPopupComponent } from './../components/user-dashboard/affiliate/owner-announcements/owner-announcement-item-popup/owner-announcement-item-popup.component';
import { DomainsListComponent } from './partials/main-lists/domains-list/domains-list.component';
import { UserLoginFormAltComponent } from './../components/auth/login-alt/user-login-form-alt/user-login-form-alt.component';
import { SubscriberItemPopupComponent } from './partials/main-lists/subscribers-list/subscriber-item-popup/subscriber-item-popup.component';
import { BuilderHeaderComponent } from './../components/user-dashboard/funnel/edit/edit-funnel/funnel-email-edit/html-builder/builder-header/builder-header.component';
import { FunnelPublishedDraftLabelComponent } from './../components/user-dashboard/funnel/edit/edit-funnel/funnel-published-draft-label/funnel-published-draft-label.component';
import { GetFunnelLinkComponent } from './../components/user-dashboard/funnel/edit/edit-funnel/get-funnel-link/get-funnel-link.component';
import { GetOfferLinkComponent } from './../components/user-dashboard/offer/edit/get-offer-link/get-offer-link.component';
import { OwnerDynamicBackButtonComponent } from './layout/owner-dashboard/owner-dynamic-back-button/owner-dynamic-back-button.component';
import { FunnelEditDetailsComponent } from '../components/user-dashboard/funnel/edit/funnel-edit-details/funnel-edit-details.component';
import { TemplateItemPageComponent } from './wizard/templates-layout/template-item/template-item-info-popup/template-item-page/template-item-page.component';
import { TemplateItemInfoPopupComponent } from './wizard/templates-layout/template-item/template-item-info-popup/template-item-info-popup.component';
import { FunnelItemPageComponent } from './wizard/funnels-layout/funnel-item/funnel-item-info-popup/funnel-item-page/funnel-item-page.component';
import { FunnelItemInfoPopupComponent } from './wizard/funnels-layout/funnel-item/funnel-item-info-popup/funnel-item-info-popup.component';
import { SelectAffiliateLinkComponent } from './../components/user-dashboard/affiliate/invite-popups/select-affiliate-links/select-affiliate-links.component';
import { AffiliatesListComponent } from './partials/main-lists/affiliates-list/affiliates-list.component';
import { ProductsListComponent } from './partials/main-lists/products-list/products-list.component';
import { AffiliateLinksListComponent } from './partials/main-lists/affiliate-links-list/affiliate-links-list.component';
import { FunnelsListComponent } from './partials/main-lists/funnels-list/funnels-list.component';
import { FullScreenDynamicHeaderComponent } from './partials/full-screen-dynamic-header/full-screen-dynamic-header.component';
import { AfiliateLinkActiveSuspendedLabelComponent } from './../components/user-dashboard/affiliate/afiliate-links/afiliate-link-active-suspended-label/afiliate-link-active-suspended-label.component';
import { InformativeSectionComponent } from './wizard/informative-section/informative-section.component';

import { FunnelsLayoutComponent } from './wizard/funnels-layout/funnels-layout.component';
import { FunnelItemComponent } from './wizard/funnels-layout/funnel-item/funnel-item.component';
import { SelectPortalComponent } from './wizard/select-portal/select-portal.component';
import { TemplatesLayoutComponent } from './wizard/templates-layout/templates-layout.component';
import { WizardSummaryComponent } from './wizard/wizard-summary/wizard-summary.component';
import { WizardStepItemComponent } from './wizard/partials/wizard-step-item/wizard-step-item.component';
import { TemplateItemComponent } from './wizard/templates-layout/template-item/template-item.component';
import { SummaryItemComponent } from './wizard/wizard-summary/summary-item/summary-item.component';
import { NoResultsSearchComponent } from './partials/main-lists/misc/no-results-search/no-results-search.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbModule,
  NgbModalConfig,
  NgbModal,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';

import { NgSelectModule } from '@ng-select/ng-select';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { FlatpickrModule } from 'angularx-flatpickr';

import { CountdownModule } from 'ngx-countdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { PlyrModule } from 'ngx-plyr';
import { QuillModule } from 'ngx-quill';
import { SocketIoModule } from 'ngx-socket-io';
// import { NgxSplitInputModule } from 'ngx-splitinput';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NotFoundPageComponent } from '../components/not-found-page/not-found-page.component';
import { AnnouncementComponent } from '../shared/partials/announcement/announcement.component';
import { HeadFilterSearchComponent } from '../shared/partials/head-filter-search/head-filter-search.component';
import { HeadTitleAddComponent } from '../shared/partials/head-title-add/head-title-add.component';
import { FileTypeIconComponent } from '../shared/partials/media-library/file-type-icon/file-type-icon.component';
import { MediaLibraryComponent } from '../shared/partials/media-library/media-library.component';
import { NumberTrackerComponent } from '../shared/partials/number-tracker/number-tracker.component';
import { StripePaymentComponent } from '../shared/partials/stripepaymentnew/stripepaymentnew.component';
import { StripepaymentSimpleComponent } from '../shared/partials/stripepaymentsimple/stripepaymentsimple.component';
import { VideoModalComponent } from '../shared/partials/video-modal/video-modal.component';

import { ProgressSpinnerDialogComponent } from '../components/progress-spinner-dialog/progress-spinner-dialog.component';
import { RegisterComponent } from '../components/register/register.component';

import { NumericDirective } from '../directives/numeric.directive';
import {
  TransitionGroupComponent,
  TransitionGroupItemDirective,
} from '../directives/transition-group-item.directive';
import { CanDeactivateDashboardGuard } from './guards/dashboardnav.guard';
import { JwtInterceptor, ErrorInterceptor } from '../interceptors';
import { TimePipe } from './pipes/dateago.pipe';
import {
  GeniricFilterPipe,
} from './pipes/generic-filter.pipe';
import {
  SpecialFilterPipe,
} from './pipes/special-filter.pipe';
import { GeniricSortPipe } from './pipes/generic-sort.pipe';
import { MediaLibraryFilterPipe } from './pipes/media-library-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { TopMenuComponent } from './layout/top-menu/top-menu.component';
import { UnauthorizedComponent } from '../components/unauthorized/unauthorized.component';
import { BrowserModule } from '@angular/platform-browser';
import { OfferCreateComponent } from '../components/user-dashboard/offer/create/create.component';

import { UserDropComponent } from './partials/user-drop/user-drop.component';
import { OwnerDropComponent } from './partials/user-drop/owner-drop/owner-drop.component';
import { AffiliateDropComponent } from './partials/user-drop/affiliate-drop/affiliate-drop.component';

import { LinkViewComponent } from './partials/link-view/link-view.component';
import { StatsItemComponent } from './partials/stats-item/stats-item.component';
import { TagInputModule } from 'ngx-chips';
import { InviteAffiliatesFromKajabiComponent } from '../components/user-dashboard/affiliate/invite-popups/invite-affiliates-from-kajabi/invite-affiliates-from-kajabi.component';
import { InviteAffiliatesComponent } from '../components/user-dashboard/affiliate/invite-popups/invite-affiliates/invite-affiliates.component';
import { ViewComponent } from '../components/user-dashboard/affiliate/my-affiliates/item/view/view.component';

import { AffiliateProfilePopoverComponent } from '../components/user-dashboard/affiliate/my-affiliates/item/item-page/affiliate-profile-popover/affiliate-profile-popover.component';
import { PercentageDirective } from '../directives/percentage.directive';
import { FastActionsButtonsComponent } from './layout/top-menu/fast-actions-buttons/fast-actions-buttons.component';
import { AffiliateDashboardStatsItemComponent } from './partials/affiliate-dashboard-stats-item/affiliate-dashboard-stats-item.component';
import { PeriodButtonComponent } from './partials/period-button/period-button.component';
import { AffiliateNotificationElementComponent } from './layout/top-menu/fast-actions-buttons/affiliate-notifications-list/affiliate-notification-element/affiliate-notification-element.component';
import { AffiliateNotificationTypeLabelComponent } from './layout/top-menu/fast-actions-buttons/affiliate-notifications-list/affiliate-notification-element/aff-noti-type-label/aff-noti-type-label.component';
import { AffiliateNotificationsListComponent } from './layout/top-menu/fast-actions-buttons/affiliate-notifications-list/affiliate-notifications-list.component';
import { OwnerNotificationElementComponent } from './layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notification-element/owner-notification-element.component';
import { OwnerNotificationTypeLabelComponent } from './layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notification-element/owner-noti-type-label/owner-noti-type-label.component';
import { OwnerNotificationsListComponent } from './layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notifications-list.component';

import { NotificationToastComponent } from './services/notification-toast/notification-toast.component';
import { AffiliateHeadStatsComponent } from '../components/affiliate-dashboard/my-dashboard/affiliate-dashboard-page/affiliate-head-stats/affiliate-head-stats.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AfiliateLinkTypeLabelComponent } from '../components/user-dashboard/affiliate/afiliate-links/afiliate-link-type-label/afiliate-link-type-label.component';
import { HighchartsChartModule } from 'highcharts-angular';


import { PeriodDateNavigatorComponent } from './partials/period-date-navigator/period-date-navigator.component';

import { AffiliateMiniItemwComponent } from '../components/user-dashboard/affiliate/my-affiliates/affiliate-mini-item/affiliate-mini-item.component';
import { AffiliateProfileCardComponent } from '../components/user-dashboard/affiliate/my-affiliates/item/item-page/affiliate-profile-card/affiliate-profile-card.component';
import { PayoutItemPopupComponent } from '../components/user-dashboard/affiliate/owner-payouts/payouts-page/more-details/payout-item-popup/payout-item-popup.component';
import { LogItemPopupComponent } from '../components/user-dashboard/affiliate/owner-payouts/log/log-item-popup/log-item-popup.component';
import { NumberSuffixPipe } from './pipes/numberSuffix.pipe';
import { DragulaModule } from 'ng2-dragula';
import { MessengerComponent } from './partials/messenger/messenger.component';
import { AttachmentComponentComponent } from './partials/messenger/attachment-component/attachment-component.component';
import { AttachmentFileViewComponent } from './partials/messenger/attachment-file-view/attachment-file-view.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MessengerChatLabelComponent } from './partials/messenger/messenger-ui-pack/messenger-chat-label/messenger-chat-label.component';
import { MessengerChatImageComponent } from './partials/messenger/messenger-ui-pack/messenger-chat-image/messenger-chat-image.component';
import { ThreadUsersPopupComponent } from './partials/messenger/thread-users-list-popup/thread-users-list-popup.component';
import { ChatUserItemComponent } from './partials/messenger/thread-users-list-popup/chat-user-item/chat-user-item.component';

import { MessengerChatBoxComponent } from './partials/messenger/messenger-chat-box/messenger-chat-box.component';
import { MessengerSelectAffiliateToChatComponent } from './partials/messenger/messenger-select-affiliate-to-chat/messenger-select-affiliate-to-chat.component';
import { MessengerChatUserMessageComponent } from './partials/messenger/messenger-ui-pack/messenger-chat-user-message/messenger-chat-user-message.component';
import { OwnerMessagesListComponent } from './layout/top-menu/fast-actions-buttons/owner-messages-list/owner-messages-list.component';
import { OwnerMessagesElementComponent } from './layout/top-menu/fast-actions-buttons/owner-messages-list/owner-messages-element/owner-messages-element.component';
import { MessengerThreadItemComponent } from './partials/messenger/messenger-thread-item/messenger-thread-item.component';
import { MessageToastComponent } from './services/message-toast/message-toast.component';
import { AnnouncementCreatePopupComponent } from '../components/user-dashboard/affiliate/owner-announcements/announcement-create-popup/announcement-create-popup.component';
import { OwnerAnnouncementUsersListPopupComponent } from '../components/user-dashboard/affiliate/owner-announcements/owner-announcement-users-list-popup/owner-announcement-users-list-popup.component';
import { AnnouncementRecipientLabelComponent } from '../components/user-dashboard/affiliate/owner-announcements/owner-announcement-ui-elements/announcement-recipients-label/announcement-recipients-label.component';
import { AnnouncementRecipientsImagesComponent } from '../components/user-dashboard/affiliate/owner-announcements/owner-announcement-ui-elements/announcement-recipients-images/announcement-recipients-images.component';
import { AnnouncementUserItemComponent } from '../components/user-dashboard/affiliate/owner-announcements/owner-announcement-users-list-popup/announcement-user-item/announcement-user-item.component';
import { AnnouncementAffiliateItemComponent } from '../components/user-dashboard/affiliate/owner-announcements/announcement-create-popup/announcement-affiliate-item/announcement-affiliate-item.component';
import { AnnouncementAffiliateLinkItemComponent } from '../components/user-dashboard/affiliate/owner-announcements/announcement-create-popup/announcement-affiliate-link-item/announcement-affiliate-link-item.component';
import { AnnouncementToastComponent } from './services/announcement-toast/announcement-toast.component';
import { AffiliateAnnouncementElementComponent } from './layout/top-menu/fast-actions-buttons/affiliate-announcements-list/affiliate-announcements-element/affiliate-announcements-element.component';
import { AffiliateAnnouncementsListViewComponent } from './layout/top-menu/fast-actions-buttons/affiliate-announcements-list/affiliate-announcements-list.component';
import { AffiliateAnnouncementsTypeLabelComponent } from './layout/top-menu/fast-actions-buttons/affiliate-announcements-list/affiliate-announcements-element/aff-announcement-label/aff-announcement-label.component';

import { AffiliateAnnouncementPopupComponent } from '../components/affiliate-dashboard/my-dashboard/affiliate-announcements/affiliate-announcement-item-popup/affiliate-announcement-item-popup.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { OffersListComponent } from './partials/main-lists/offers-list/offers-list.component';
import { ItemGalleryComponent } from './wizard/item-gallery/item-gallery.component';
import { SwiperModule } from 'swiper/angular';
import { GoToPortalComponent } from './partials/go-to-portal/go-to-portal.component';
import { StepsUiViewComponent } from './wizard/steps-ui-view/steps-ui-view.component';
// import { DiceTemplateEditorModule } from 'dice-template-editor';
import { HtmlBuilderComponent } from '../components/user-dashboard/funnel/edit/edit-funnel/funnel-email-edit/html-builder/html-builder.component';
import { SubscribersListComponent } from './partials/main-lists/subscribers-list/subscribers-list.component';
import { ImagePreviewPopoverComponent } from './wizard/item-gallery/image-preview-popover/image-preview-popover.component';
import { PlanFeaturesListComponent } from './partials/plan-features-list/plan-features-list.component';
import { EmailVewWithCopyComponent } from './partials/email-vew-with-copy/email-vew-with-copy.component';
import { InfoTipComponent } from './partials/info-tip/info-tip.component';
import { PaymentMethodViewComponent } from './partials/payment-method-view/payment-method-view.component';
import { BrowserAddressBarComponent } from './partials/browser-address-bar/browser-address-bar.component';
import { GetHelpMenuComponent } from './partials/user-drop/get-help-menu/get-help-menu.component';
import { MessengerChatPopupComponent } from './partials/messenger/messenger-chat-popup/messenger-chat-popup.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { GlobalSearchComponent } from './partials/global-search/global-search.component';
import { GlobalSearchResultsBoxComponent } from './partials/global-search/global-search-results-box/global-search-results-box.component';
import { GlobalSearchPreferencesComponent } from './partials/global-search/global-search-preferences/global-search-preferences.component';
import { GlobalSearchHistoryComponent } from './partials/global-search/global-search-history/global-search-history.component';
import { DashboardStatsCardComponent } from './partials/dashboard-stats-card/dashboard-stats-card.component';
import { GlobalLearmoModalComponent } from './partials/global-learmo-modal/global-learmo-modal.component';
import { DynamicTopLevelNavComponent } from './partials/dynamic-top-level-nav/dynamic-top-level-nav.component';
import { DynamicInfoTooltipComponent } from './partials/dynamic-info-tooltip/dynamic-info-tooltip.component';
import { MediaLibraryUploadZoneComponent } from './partials/media-library/media-library-upload-zone/media-library-upload-zone.component';
import { ProductCreateComponent } from '../components/user-dashboard/product/create/product-create/product-create.component';
import { ProductCreateWizardComponent } from '../components/user-dashboard/product/create/product-create-wizard/product-create-wizard.component';
import { ProductTypeMoreDetailsComponent } from '../components/user-dashboard/product/create/product-create-wizard/product-type-wizard-step/product-type-more-details/product-type-more-details.component';
import { NgxSkeletonModule } from 'ngx-skeleton';
import { IntegratePortalPaymentsComponent } from '../components/user-dashboard/portal/view/portal-payment/integrate/integrate.component';
import { MethodFormComponent } from '../components/user-dashboard/portal/view/portal-payment/integrate/method-form/method-form.component';
import { BtcpaySpecialMethodComponent } from '../components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay-special-method/btcpay-special-method.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { LoginGalleryComponent } from '../components/auth/login-alt/login-gallery/login-gallery.component';
import { FunnelCreateWizardComponent } from '../components/user-dashboard/funnel/create/funnel-create-wizard/funnel-create-wizard.component';
import { CreatePagePopupComponent } from '../components/user-dashboard/portal/view/portal-content/portal-pages/create-page-popup/create-page-popup.component';
import { LearmoStoreModule } from './store/store.module';
import { SalesItemViewComponent } from '../components/user-dashboard/owner-earnings/sales/sales-item/sales-item-view/sales-item-view.component';
import { AccountSetupWizardComponent } from './layout/owner-dashboard/account-setup-wizard/account-setup-wizard.component';
import { ApplicationLoadingComponentComponent } from './layout/owner-dashboard/application-loading-component/application-loading-component.component';
import { LearmoLogoComponent } from './partials/learmo-logo/learmo-logo.component';
import { WelcomeStepComponent } from './layout/owner-dashboard/account-setup-wizard/welcome-step/welcome-step.component';
import { RefundItemPopupComponent } from '../components/user-dashboard/refund/refund-item/refund-item-popup/refund-item-popup.component';
import { RefundItemViewComponent } from '../components/user-dashboard/refund/refund-item/refund-item-view/refund-item-view.component';
import { RefundItemStatusLabelComponent } from './partials/main-lists/refund-list/refund-list-item/refund-item-status-label/refund-item-status-label.component';
import { DynamicCheckboxCardComponent } from './partials/dynamic-checkbox-card/dynamic-checkbox-card.component';
import { ContactCreateComponent } from '../components/user-dashboard/contacts/create/create.component';
import { ContactTypeViewComponent } from '../components/user-dashboard/contacts/create/contact-type-view/contact-type-view.component';
import { CreateBlogPostPopupComponent } from '../components/user-dashboard/portal/view/portal-blog/portal-blog-posts/create-blog-post-popup/create-blog-post-popup.component';
import { PaypalIntegrationFromComponent } from '../components/user-dashboard/portal/view/portal-payment/integrate/method-form/paypal/paypal-integration-from/paypal-integration-from.component';
import { OfferEditComponent } from '../components/user-dashboard/offer/edit/edit.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const systemLists = [
// System Lists
ContactsListComponent,
ContactsListItemComponent,
OffersListComponent,
RefundListComponent,
RefundListItemComponent,
EarningsListComponent,
EarningsListItemComponent,
FunnelsListComponent,
FunnelListItemComponent,
PagesListComponent,
PageListItemComponent,
BlogPostsListComponent,
BlogPostsListItemComponent,
CreateBlogPostPopupComponent,
DynamicPostStatusComponent,
];

const UiLibraries = [
  NgxSkeletonLoaderModule,
  NgxSkeletonModule
]


const skeletonComponents = [
  ItemHeadSkeletonComponent
]
@NgModule({
  declarations: [
    LearmoLogoComponent,
    AccountSetupWizardComponent,
    WelcomeStepComponent,
    PortalIdentityStepComponent,
    SiteTemplateStepComponent,
    WelcomeOnboardingGalleryComponent,
    ApplicationLoadingComponentComponent,
    WebsiteFinalizingComponent,
    UnauthorizedComponent,
    OfferCreateComponent,
    FunnelCreateComponent,
    GetOfferLinkComponent,
    GetProductLinkComponent,
    TopMenuComponent,
    UserDropComponent,
    ProgressSpinnerDialogComponent,
    StripePaymentComponent,
    StripepaymentSimpleComponent,
    VideoModalComponent,
    GeniricFilterPipe,
    GeniricSortPipe,
    ReplacePipe,
    SortSelectedFirstPipe,
    RemoveSpacesPipe,
    SpecialFilterPipe,
    TimePipe,
    FullNamePipe,
    FileSizePipe,
    SafePipe,
    MediaLibraryFilterPipe,
    NumberSuffixPipe,
    UserLoginFormAltComponent,
    LoginMethodItemComponent,
    UserRegisterFormComponent,
    WaitListFormComponent,
    LoginGalleryComponent,
    PasswordMeterComponent,
    AnnouncementComponent,
    RegisterComponent,
    WaitListComponent,
    NotFoundPageComponent,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
    TextareaAutoresizeDirective,
    NumericDirective,
    PercentageDirective,
    InputAutoFocusDirective,
    NumberTrackerComponent,
    HeadFilterSearchComponent,
    HeadTitleAddComponent,
    MediaLibraryComponent,
    MediaLibraryModalComponent,
    MediaLibraryStorageMeterComponent,
    MediaLibraryGridFileViewComponent,
    MediaLibraryUploadFormComponent,
    MediaLibraryCropperComponent,
    MediaLibraryUploadZoneComponent,
    MediaLibraryChangeZoneComponent,
    MediaLibraryViewOnlyComponent,
    FileTypeIconComponent,
    UserDropComponent,
    OwnerDropComponent,
    AffiliateDropComponent,
    UsersSymbolsGroupComponent,
    LinkViewComponent,
    StatsItemComponent,
    AffiliateDashboardStatsItemComponent,
    InviteAffiliatesComponent,
    InviteAffiliatesFromKajabiComponent,
    ViewComponent,
    AffiliateProfilePopoverComponent,
    FastActionsButtonsComponent,
    PeriodButtonComponent,
    PeriodDateNavigatorComponent,
    AffiliateNotificationElementComponent,
    AffiliateNotificationTypeLabelComponent,
    AffiliateNotificationsListComponent,
    OwnerNotificationElementComponent,
    OwnerNotificationTypeLabelComponent,
    OwnerNotificationsListComponent,
    AffiliateAnnouncementElementComponent,
    AffiliateAnnouncementsListViewComponent,
    AffiliateAnnouncementsTypeLabelComponent,
    AffiliateAnnouncementPopupComponent,
    NotificationToastComponent,
    AnnouncementToastComponent,
    MessageToastComponent,
    AffiliateHeadStatsComponent,
    AfiliateLinkTypeLabelComponent,
    SalesItemPopupComponent,
    RefundItemPopupComponent,
    SalesItemViewComponent,
    RefundItemViewComponent,
    AffiliateMiniItemwComponent,
    AffiliateProfileCardComponent,
    PayoutItemPopupComponent,
    LogItemPopupComponent,
    MessengerComponent,
    AttachmentComponentComponent,
    AttachmentFileViewComponent,
    MessengerChatLabelComponent,
    MessengerChatImageComponent,
    ThreadUsersPopupComponent,
    ChatUserItemComponent,
    MessengerChatBoxComponent,
    MessengerSelectAffiliateToChatComponent,
    MessengerChatUserMessageComponent,
    OwnerMessagesListComponent,
    OwnerMessagesElementComponent,
    MessengerThreadItemComponent,
    AnnouncementCreatePopupComponent,
    ContactCreateComponent,
    ContactTypeViewComponent,
    AnnouncementRecipientsImagesComponent,
    AnnouncementRecipientLabelComponent,
    OwnerAnnouncementUsersListPopupComponent,
    AnnouncementUserItemComponent,
    AnnouncementAffiliateItemComponent,
    AnnouncementAffiliateLinkItemComponent,
    NoResultsSearchComponent,
    AfiliateLinkActiveSuspendedLabelComponent,
    // Select Items
    IconsSelectorComponent,
    LanguageSelectorComponent,
    // Wizard Componenents
    TemplatesLayoutComponent,
    WizardSummaryComponent,
    WizardStepItemComponent,
    TemplateItemComponent,
    SummaryItemComponent,
    SelectPortalComponent,
    FunnelItemComponent,
    FunnelsLayoutComponent,
    FunnelCreateWizardComponent,
    InformativeSectionComponent,
    FunnelItemInfoPopupComponent,
    FunnelItemPageComponent,
    StepsUiViewComponent,

    SystemItemsSequenceComponent,
    PointerGuideComponent,

    TemplateItemInfoPopupComponent,
    TemplateItemPageComponent,
    // Funnels Area
    FullScreenDynamicHeaderComponent,
    FunnelEditDetailsComponent,
    GetFunnelLinkComponent,
    FunnelPublishedDraftLabelComponent,

    


    CreatePagePopupComponent,
    DynamicPageStatusComponent,
    AffiliateLinksListComponent,
    ProductsListComponent,
    StudentsListComponent,
    StudentListItemComponent,
    OfferListItemComponent,
    OfferEditComponent,
    OfferListMinimalItemComponent,
    DynamicOfferStatusComponent,
    AffiliatesListComponent,
    DomainsListComponent,
    OwnerDynamicBackButtonComponent,
    LoadingListItemComponent,
    LoadingListComponent,
    ListThumbnailComponent,

    ListIsEmptyComponent,
    PaginationUnitComponent,

    MoneyModelPreviewComponent,


    // System Lists
    ...systemLists,

    ...skeletonComponents,


    // System Popovers
    StudentProfileCardComponent,

    // System Components
    PublishedLabelComponent,
    DraftLabelComponent,
    SystemLabelComponent,

    // Invitation
    SelectAffiliateLinkComponent,
    ItemGalleryComponent,
    GoToPortalComponent,
    HtmlBuilderComponent,
    BuilderHeaderComponent,
    SubscribersListComponent,
    SubscriberItemPopupComponent,
    ImagePreviewPopoverComponent,
    PlanFeaturesListComponent,
    EmailVewWithCopyComponent,
    InfoTipComponent,
    OwnerAnnouncementPopupComponent,
    CardLoaderDirectives,
    StickybitsDirective,
    ScrollSnapDirective,
    LearmoStickDirective,
    LearmoVisibleDirective,
    PaymentMethodViewComponent,
    SystemPaymentMethodViewComponent,

    // Domains
    DomainDetailsCardComponent,
    DomainDetailsPopoverComponent,
    DomainPortalSettingsComponent,
    BrowserAddressBarComponent,
    BrowserWebsiteViewComponent,
    PortalIdentitySettingsModalComponent,
    PortalIdentitySettingsComponent,
    EditPortalSubdomainComponent,
    AddPortalCustomDomainComponent,
    GetHelpMenuComponent,
    MessengerChatPopupComponent,
    PortalIdentityTooltipComponent,
    GlobalSearchComponent,
    GlobalSearchResultsBoxComponent,
    GlobalSearchPreferencesComponent,
    GlobalSearchHistoryComponent,
    DashboardStatsCardComponent,
    GlobalLearmoModalComponent,
    MainNavBarComponent,
    MainNavBarListComponent,
    DynamicTopLevelNavComponent,
    DynamicSecondLevelNavComponent,
    HorizontalScrollDirective,
    ProductListItemComponent,
    DynamicDropdownComponent,
    DynamicCarousellComponent,
    DynamicActionButtonsComponent,
    ButtonViewComponent,
    DynamicInfoTooltipComponent,
    DynamicAiButtonComponent,
    DynamicItemsCardComponent,

    // Payments
    IntegratePortalPaymentsComponent,
    MethodFormComponent,
    PaypalIntegrationFromComponent,
    StripeIntegrationFromComponent,
    BtcpayIntegrationFormComponent,
    BtcpaySpecialMethodComponent,

    // Product Area
    ProductCreateComponent,
    ProductCreateWizardComponent,
    ProductTypeMoreDetailsComponent,
    ProductTypePreviewComponent,
    PricingSliderComponent,
    PricingInputComponent,
    LearmoRobotComponent,
    LearmoTargetComponent,
    LearmoSubscriptionWidgetComponent,
    // AI Area
    AiIdeaGeneratorComponent,
    AiCategorySelectComponent,
    AiCtaCardComponent,
    AiOutlineCtaCardComponent,
    AiCursorComponent,
    AiThinkingLoaderComponent,

    LearmoEditorComponent,
    LearmoBlockEditorComponent,
    EditorToolbarComponent,

    
    // Global Fields
    ProductSelectorComponent,
    OfferSelectorComponent,
    MoneyInputComponent,
    GlobalRadioInfoCardComponent,

    //  OnBoarding Modals
    ProductOnboardingGalleryComponent,
    FunnelOnboardingGalleryComponent,
    SellingOnboardingGalleryComponent,
    AccountStepsTrackingSidewidgetComponent,
    SetupStepsComponent,
    AccountSetupItemComponent,


    // Payments Sections
    PaymentMethodItemComponent,
    AddPaymentMethodModalComponent,
    RefundItemStatusLabelComponent,
    DynamicCheckboxCardComponent,


    // Subscription Area
    SubscriptionValidUntilComponent,
    SubscriptionStatusLabelComponent,

    ColorSelectComponent,


    // Global Listeners
    PaypalIntegrationListenerComponent,
  ],
  imports: [
    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxChartsModule,
    CountdownModule,
    ImageCropperModule,
    SocketIoModule,
    BsDatepickerModule.forRoot(),
    FlatpickrModule.forRoot(),
    InfiniteScrollModule,
    NgSelectModule,
    QuillModule.forRoot(),
    NgApexchartsModule,
    SwiperModule,
    // PlyrModule,
    // DiceTemplateEditorModule,

    NgxTippyModule,

    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', preventDuplicates: true }),
    TagInputModule,
    HighchartsChartModule,
    DragulaModule.forRoot(),
    GalleryModule.withConfig({
      // thumbView: 'contain',
    }),
    LightboxModule,
    NgOtpInputModule,
    AngularSvgIconModule.forRoot(),
    NgxIntlTelInputModule,
    ...UiLibraries,


  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxChartsModule,
    CountdownModule,
    ImageCropperModule,
    SocketIoModule,
    BsDatepickerModule,
    FlatpickrModule,
    InfiniteScrollModule,
    NgSelectModule,
    QuillModule,

    NgApexchartsModule,
    // TODO: Remove Quill
    SwiperModule,
    // PlyrModule,
    // DiceTemplateEditorModule,


    ToastrModule,
    HighchartsChartModule,
    DragulaModule,
    GalleryModule,
    LightboxModule,
    NgOtpInputModule,
    AngularSvgIconModule,
    NgxIntlTelInputModule,
    ...UiLibraries,

    NgxTippyModule,

    // Components
    LearmoLogoComponent,
    AccountSetupWizardComponent,
    WelcomeStepComponent,
    PortalIdentityStepComponent,
    SiteTemplateStepComponent,
    WelcomeOnboardingGalleryComponent,
    ApplicationLoadingComponentComponent,
    WebsiteFinalizingComponent,
    UnauthorizedComponent,
    OfferCreateComponent,
    FunnelCreateComponent,
    GetOfferLinkComponent,
    GetProductLinkComponent,
    TopMenuComponent,
    VideoModalComponent,
    AnnouncementComponent,
    ProgressSpinnerDialogComponent,

    UserLoginFormAltComponent,
    LoginMethodItemComponent,
    WaitListFormComponent,
    UserRegisterFormComponent,
    LoginGalleryComponent,

    PasswordMeterComponent,
    StripePaymentComponent,
    StripepaymentSimpleComponent,
    VideoModalComponent,
    AnnouncementComponent,
    RegisterComponent,
    WaitListComponent,
    NotFoundPageComponent,
    // Pipes
    GeniricFilterPipe,
    GeniricSortPipe,
    ReplacePipe,
    SortSelectedFirstPipe,
    RemoveSpacesPipe,
    SpecialFilterPipe,
    TimePipe,
    FullNamePipe,
    FileSizePipe,
    SafePipe,
    MediaLibraryFilterPipe,
    NumberSuffixPipe,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
    TextareaAutoresizeDirective,
    NumericDirective,
    PercentageDirective,
    InputAutoFocusDirective,
    NumberTrackerComponent,
    HeadFilterSearchComponent,
    HeadTitleAddComponent,
    MediaLibraryComponent,
    MediaLibraryModalComponent,
    MediaLibraryStorageMeterComponent,
    MediaLibraryGridFileViewComponent,
    MediaLibraryUploadFormComponent,
    MediaLibraryCropperComponent,
    MediaLibraryUploadZoneComponent,
    MediaLibraryChangeZoneComponent,
    MediaLibraryViewOnlyComponent,
    FileTypeIconComponent,
    UserDropComponent,
    OwnerDropComponent,
    AffiliateDropComponent,
    UsersSymbolsGroupComponent,
    LinkViewComponent,
    StatsItemComponent,
    AffiliateDashboardStatsItemComponent,
    TagInputModule,
    InviteAffiliatesComponent,
    InviteAffiliatesFromKajabiComponent,
    ViewComponent,
    AffiliateProfilePopoverComponent,
    FastActionsButtonsComponent,
    PeriodButtonComponent,
    PeriodDateNavigatorComponent,
    AffiliateNotificationElementComponent,
    AffiliateNotificationTypeLabelComponent,
    AffiliateNotificationsListComponent,
    OwnerNotificationElementComponent,
    OwnerNotificationTypeLabelComponent,
    OwnerNotificationsListComponent,
    AffiliateAnnouncementElementComponent,
    AffiliateAnnouncementsListViewComponent,
    AffiliateAnnouncementsTypeLabelComponent,
    AffiliateAnnouncementPopupComponent,
    NotificationToastComponent,
    AnnouncementToastComponent,
    MessageToastComponent,
    AffiliateHeadStatsComponent,
    AfiliateLinkTypeLabelComponent,
    SalesItemPopupComponent,
    RefundItemPopupComponent,
    SalesItemViewComponent,
    RefundItemViewComponent,
    AffiliateMiniItemwComponent,
    AffiliateProfileCardComponent,
    PayoutItemPopupComponent,
    LogItemPopupComponent,
    MessengerComponent,
    ThreadUsersPopupComponent,
    ChatUserItemComponent,
    MessengerChatBoxComponent,
    MessengerSelectAffiliateToChatComponent,
    MessengerChatUserMessageComponent,
    OwnerMessagesListComponent,
    OwnerMessagesElementComponent,
    MessengerThreadItemComponent,
    AnnouncementCreatePopupComponent,
    ContactCreateComponent,
    ContactTypeViewComponent,
    AnnouncementRecipientsImagesComponent,
    AnnouncementRecipientLabelComponent,
    OwnerAnnouncementUsersListPopupComponent,
    AnnouncementUserItemComponent,
    AnnouncementAffiliateItemComponent,
    AnnouncementAffiliateLinkItemComponent,
    NoResultsSearchComponent,
    AfiliateLinkActiveSuspendedLabelComponent,
    // Select Items
    IconsSelectorComponent,
    LanguageSelectorComponent,
    // Wizard Components
    TemplatesLayoutComponent,
    WizardSummaryComponent,
    WizardStepItemComponent,
    TemplateItemComponent,
    SummaryItemComponent,
    SelectPortalComponent,
    FunnelItemComponent,
    FunnelsLayoutComponent,
    FunnelCreateWizardComponent,
    InformativeSectionComponent,
    StepsUiViewComponent,
    SystemItemsSequenceComponent,

    FunnelItemInfoPopupComponent,
    FunnelItemPageComponent,

    TemplateItemInfoPopupComponent,
    TemplateItemPageComponent,
    // Funnels Area
    FullScreenDynamicHeaderComponent,
    FunnelEditDetailsComponent,
    GetFunnelLinkComponent,
    FunnelPublishedDraftLabelComponent,

    // System Lists
    ...systemLists,
    ...skeletonComponents,

    CreatePagePopupComponent,
    DynamicPageStatusComponent,
    AffiliateLinksListComponent,
    ProductsListComponent,
    StudentsListComponent,
    StudentListItemComponent,

  

    OfferListItemComponent,
    OfferEditComponent,
    OfferListMinimalItemComponent,
    DynamicOfferStatusComponent,
    AffiliatesListComponent,
    DomainsListComponent,
    OwnerDynamicBackButtonComponent,
    LoadingListItemComponent,
    LoadingListComponent,
    ListThumbnailComponent,

    ListIsEmptyComponent,
    PaginationUnitComponent,

    MoneyModelPreviewComponent,

    // System Popovers
    StudentProfileCardComponent,

    // System Components
    PublishedLabelComponent,
    DraftLabelComponent,
    SystemLabelComponent,

    // Invitation
    SelectAffiliateLinkComponent,
    GoToPortalComponent,
    HtmlBuilderComponent,
    BuilderHeaderComponent,
    SubscribersListComponent,
    SubscriberItemPopupComponent,
    PlanFeaturesListComponent,

    EmailVewWithCopyComponent,
    InfoTipComponent,
    OwnerAnnouncementPopupComponent,
    CardLoaderDirectives,
    StickybitsDirective,
    ScrollSnapDirective,
    LearmoStickDirective,
    LearmoVisibleDirective,
    PaymentMethodViewComponent,
    SystemPaymentMethodViewComponent,

    // Domains
    DomainDetailsCardComponent,
    DomainDetailsPopoverComponent,
    DomainPortalSettingsComponent,
    BrowserAddressBarComponent,
    BrowserWebsiteViewComponent,
    PortalIdentitySettingsModalComponent,
    PortalIdentitySettingsComponent,
    EditPortalSubdomainComponent,
    AddPortalCustomDomainComponent,
    GetHelpMenuComponent,
    MessengerChatPopupComponent,
    PortalIdentityTooltipComponent,

    PointerGuideComponent,
    DashboardStatsCardComponent,
    MainNavBarComponent,
    MainNavBarListComponent,
    DynamicTopLevelNavComponent,
    DynamicSecondLevelNavComponent,
    HorizontalScrollDirective,
    ProductListItemComponent,
    DynamicDropdownComponent,
    DynamicCarousellComponent,
    DynamicActionButtonsComponent,
    ButtonViewComponent,
    DynamicInfoTooltipComponent,
    DynamicAiButtonComponent,

    DynamicItemsCardComponent,

    // Payments
    IntegratePortalPaymentsComponent,
    MethodFormComponent,
    PaypalIntegrationFromComponent,
    StripeIntegrationFromComponent,
    BtcpayIntegrationFormComponent,
    BtcpaySpecialMethodComponent,

    // Product Area
    ProductCreateComponent,
    ProductCreateWizardComponent,
    ProductTypeMoreDetailsComponent,
    ProductTypePreviewComponent,
    PricingSliderComponent,
    PricingInputComponent,
    LearmoRobotComponent,
    LearmoTargetComponent,
    LearmoSubscriptionWidgetComponent,
    // AI Area
    AiIdeaGeneratorComponent,
    AiCategorySelectComponent,
    AiCtaCardComponent,
    AiOutlineCtaCardComponent,
    AiCursorComponent,
    AiThinkingLoaderComponent,
    LearmoEditorComponent,
    LearmoBlockEditorComponent,
    
    EditorToolbarComponent,
    
    // Global Fields
    ProductSelectorComponent,
    OfferSelectorComponent,
    MoneyInputComponent,
    GlobalRadioInfoCardComponent,
    //  OnBoarding Modals
    ProductOnboardingGalleryComponent,
    FunnelOnboardingGalleryComponent,
    SellingOnboardingGalleryComponent,
    AccountStepsTrackingSidewidgetComponent,
    SetupStepsComponent,
    AccountSetupItemComponent,

    // Payments Sections
    PaymentMethodItemComponent,
    AddPaymentMethodModalComponent,

    RefundItemStatusLabelComponent,
    DynamicCheckboxCardComponent,
    // Subscription Area
     SubscriptionValidUntilComponent,
     SubscriptionStatusLabelComponent,

     ColorSelectComponent,


     // Global Listeners
     PaypalIntegrationListenerComponent
  ],
  providers: [NgbDropdown],
})
export class SharedModule {}
