import { InOutAnimation } from './../../animations/animations';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { AuthenticationService } from '../../services';
import { ProfileUiControlService } from '../../services/profile-ui-control.service';
import {
  DashboardSessionModeType,
  UserDashboardService,
} from '../../services/user-dashboard.service';
import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';

@Component({
  selector: 'app-user-drop',
  templateUrl: './user-drop.component.html',
  styleUrls: ['./user-drop.component.css'],
  animations: [InOutAnimation],
})
export class UserDropComponent implements OnInit, OnDestroy {
  @Input() imgClasses: string = 'symbol-circle symbol-35px symbol-md-40px';
  @Input() minimize: boolean = false;
  userDropdownOpen = false;
  // public currentUser: User;

  onUpdateProfileEventSubscription: Subscription;
  dashboardSessionModeType = DashboardSessionModeType;
  constructor(
    public userDashboardService: UserDashboardService,
    private authenticationService: AuthenticationService,
    public profileUiService: ProfileUiControlService,
    public sharedProfileService: SharedProfileService
  ) {}
  ngOnDestroy(): void {
    // this.onUpdateProfileEventSubscription.unsubscribe();
  }

  ngOnInit(): void {

  }

  toggleUserDropdown() {
    this.userDropdownOpen = !this.userDropdownOpen;
  }

  logout() {
    this.authenticationService.logout();
  }
}
