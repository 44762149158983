import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';


class TextCompletionChunk {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    index: number;
    finish_reason: string | null;
    text: string;
    logprobs: {
      top_logprobs: Array<Record<string, unknown>>;
    };
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  // private fallbackApiUrl = 'https://ai-beta.learmo.dev/api/v1/generate';
  private apiUrl = 'https://ai-beta.learmo.dev/v1/completions';
  // private apiUrl = 'http://127.0.0.1:5000/v1/completions';
  // private apiUrl = 'https://ai-gen2.learmo.dev/v1/completions' // AI GEN2

  constructor(private http: HttpClient) {}

  generateRandomNumber(): number | null {
    // let exclude = [1,2,4,6,7,8,9];

    // let include = [3,5,10];
    let include = [1,2,3,4,5,6,7,8,9];
    return include[Math.floor(Math.random() * include.length)] ?? null;
  }

  //  generateRandomNumber(): number {
  //   // let exclude = [1,2,4,6,7,8,9];


  //   // Generate a random number between 1 and 10
  //   const randomNumber: number = Math.floor(Math.random() * 10) + 1;
  //   if (exclude.includes(randomNumber)) {
  //     return this.generateRandomNumber();
  //   }
  //   return randomNumber;
  // }

  getCompletion(prompt: string, stream = true, template: boolean = true): Observable<any> {
    if (template) {
    // prompt = `USER:{${prompt}}
    // ASSISTANT:`;
    }

    // "prompt": "{Generate a crash course outline on the topic 'Introduction to Environmental Mathematics: Analyzing and Modeling Sustainable Systems ' The course should consist of four modules + Module 5 Conclusion, each containing three lessons. Please provide only titles without any extra information. Avoid generating any concluding statements or additional text after the requested outline:}### Response:",
    // const token = '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';
    if (!!stream) {
      return this.chatStream(this.apiUrl, prompt);
    }
    return this.getResult(this.apiUrl, prompt);
  }

  private getResult(url, prompt): Observable<any> {
    const body = {
      model: 'WestLake-Learmo',
      // model: 'TheBloke_Mistral-7B-Instruct-v0.2-AWQ',
      prompt,
      max_tokens: 2048,
      temperature: 1.99,
      top_p: 0.9,
      seed: this.generateRandomNumber(),
      stream: false,
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      // .set('Authorization', `Bearer ${token}`)
      .set('disableInterceptor', 'true');

    const options = { headers };

    return this.http.post(this.apiUrl, body, options);
  }
  private chatStream(url, prompt) {
    const body = {
      model: 'WestLake-Learmo',
      // model: 'TheBloke_Mistral-7B-Instruct-v0.2-AWQ',
      prompt,
      max_tokens: 2048,
      temperature: 1.8,
      top_p: 0.18,
      seed: this.generateRandomNumber(),
      stream: true,
    };
    const bodyString: any = JSON.stringify(body);
    return this.makeTheRequest(url, bodyString)
  }



  makeTheRequest(primaryUrl: string, body: string): Observable<string> {
    return new Observable<string>((observer) => {
      fetch(primaryUrl, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/stream+json',
          disableInterceptor: 'true',
        },
      })
      .then((response) => {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        if (!response.ok) {
          observer.error();
          return;
        }

        function processLine(line: string) {
          const match = line.match(/^data: (.+)$/s);
          if (match && match[1] != "[DONE]") {
            const responseJSONText = match[1];

            try {
              const ResponseJSON: TextCompletionChunk = JSON.parse(responseJSONText);
              const text = ResponseJSON.choices[0].text;
              const usage = ResponseJSON.usage;

              observer.next(text);
            } catch (error) {
              console.error('Error parsing JSON:', error);


              observer.error(error);
            }
          }
        }

        function processChunk(chunk: string) {
          const lines = chunk.split('\n');
          lines.forEach(processLine);
        }

        function push() {
          reader?.read().then(({ done, value }) => {
            if (done) {
              observer?.complete();
              return;
            }

            const responseText = decoder.decode(value);
            processChunk(responseText);

            // Continue reading the stream
            push();
          });
        }

        push();
      })
      .catch((err: Error) => {
        Swal.fire({
          title: 'Temporary Unavailability',
          text: 'The AI service is currently unavailable. We apologize for the inconvenience. Please try again later.',
          icon: 'warning',
          preConfirm: () => {
            observer.error(err);
          },
        });
      });
    });
  }



}
