import { Plan } from 'src/app/models/plans';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plan-features-list',
  templateUrl: './plan-features-list.component.html',
  styleUrls: ['./plan-features-list.component.css'],
})
export class PlanFeaturesListComponent implements OnInit {
  @Input() plan: Plan;
  constructor() {}

  ngOnInit(): void {}
}
