import { AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

export class TopLevelNavMenuItem {
  label: string; url: string; iconPath?: string; subMenu?: TopLevelNavMenuItem[]; helpText?: string; customId?: string; randId?: string; soon?: boolean
}
@Component({
  selector: 'app-dynamic-top-level-nav',
  templateUrl: './dynamic-top-level-nav.component.html',
  styleUrls: ['./dynamic-top-level-nav.component.css']
})
export class DynamicTopLevelNavComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() forceSkeletonLoader: boolean = false;
  @Input() customClasses = 'rounded-pill shadow-sm border-transparent';
  detectedActiveItem = false;
  currentMenuItem: TopLevelNavMenuItem;
  _menuItems: TopLevelNavMenuItem[] = []

  isCollapsed: boolean = true;

  subscriptions: Subscription[] = []
  @Input('menuItems') set MenuItems(value: TopLevelNavMenuItem[]) {
    this._menuItems = value;
    this._menuItems.forEach(item => {
      item.randId = this.generateUniqueString();
    })

    this.whichItemIsActive()
  }


  constructor(private router: Router) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.trackActiveItem();
  }

  ngAfterViewChecked(): void {
    this.whichItemIsActive()
  }
  // ngAfterViewInit(): void {
  // }

  calculateSubMenuTransform(item: TopLevelNavMenuItem, timeout: number = 0) {
    if (!item?.subMenu?.length) {
      return false;
    }
    setTimeout(() => {
      let mainmenuElement = document.getElementById('learmo-main-menu');
      let activeElement = document.getElementById(item.randId)
      let submenuElement = document.getElementById('learmo-sub-menu');

      // Get the position of the active nav item relative to the parent container
      const rectNavItem = activeElement.getBoundingClientRect();
      const rectParentContainer = mainmenuElement.getBoundingClientRect();

      const positionFromLeft = rectNavItem.left - rectParentContainer.left;
      submenuElement?.style?.setProperty('transform', `translateX(${positionFromLeft}px)`)
    }, timeout);

  }

  trackActiveItem() {
    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.whichItemIsActive(event.url, true);
      })
    )
  }

  onClickMainItem(item: TopLevelNavMenuItem) {
    if (!!item?.soon) return;

    this.currentMenuItem = item;


    // this.calculateSubMenuTransform(item)
  }

  backToSelectItem() {
    this.currentMenuItem = undefined;
  }

  whichItemIsActive(currentActive = window.location.href, force: boolean = false) {
    if (!this.detectedActiveItem || force) {
      let item = this._menuItems.find(a => currentActive.includes(a.url));
      this.onClickMainItem(item);
      if (!!this.currentMenuItem) {
        this.detectedActiveItem = true;
      }
    }
  }


  generateUniqueString(): string {
    // Generate a random string (you can customize the length and characters)
    const randomString = Math.random().toString(36).substring(2, 8);

    // Get the current timestamp
    const timestamp = new Date().getTime();

    // Combine the random string and timestamp to create a unique short string
    const uniqueString = randomString + timestamp;

    return uniqueString;
  }

}
