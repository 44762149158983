import { Injectable } from '@angular/core';
import * as Stripe from '@stripe/stripe-js';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import {
  OneTimePaymentResult,
  OntTimePaymentOutputBtcpay,
  PaymentMethodModel,
} from '../../models/payment.model';
import { ApiService } from './lsg.api.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private readonly api: ApiService) {}

  stripe: Stripe.Stripe;

  async getAllPaymentMethodsForUser(sorted: boolean = true) {
    const paymentMethods: PaymentMethodModel[] = await this.api
      .getAllPaymentMethod(sorted)
      .toPromise();

    // if (paymentMethods.length > 0) {
    //   this.selectedPaymentMethod = paymentMethods[0].id;
    // }
    return paymentMethods;
  }

  async loadStripe() {
    this.stripe = await Stripe.loadStripe(
      'pk_test_51Ihzu3BfQfhFkH5JxaoPX15sIv2MJ5KNSEiFxnQYx35H3VSgGsHxp88hGqmUEMitqEKYVrdkRh04hoP44KNHHi9i00lpprtgsL'
    );
    // this.stripe = await Stripe.loadStripe('pk_test_xwHSGWZUGESEUVjndvRfuTvZ00h9K5oF5W'); DyedRockCom

    this.stripe = await Stripe.loadStripe(environment.stripeSecret);
  }

  async payOneTimeStripe(
    amountInUsd: number,
    domain: string,
    pid?: string | undefined,
    couponId: number | undefined = undefined,
    errorHandler: any = undefined
  ) {
    const response: any = await this.api
      .doPayment(
        'stripe',
        'domain',
        undefined,
        couponId,
        undefined,
        amountInUsd,
        domain,
        pid,
        errorHandler
      )
      .toPromise();

    if (!!response.created) {
    } else {
      const res = response as { clientSecret: string; transactionId: string };
      // const stripe: Stripe.Stripe = await Stripe.loadStripe(res.pk);
      const errorModel: { paymentIntent?: PaymentIntent; error?: StripeError } =
        await this.stripe?.confirmCardPayment(res.clientSecret);

      if (!!errorModel?.error) {
        return { transactionId: null, status: false };
      }

      return {
        transactionId: res.transactionId,
        status: errorModel.paymentIntent.status === 'succeeded',
      };
      // return errorModel.paymentIntent.status === 'succeeded';
    }
  }
  async payBtcpay(
    amountInUsd: number,
    domain: string,
    couponId: number | undefined = undefined,
    coinType: 'BTC' | '',
    errorHandler: any = undefined
  ) {
    const response: OntTimePaymentOutputBtcpay = await this.api
      .doPayment(
        'btcpay',
        'domain',
        coinType,
        couponId,
        undefined,
        amountInUsd,
        domain,
        errorHandler
      )
      .toPromise();
    return response;
  }

  subscriptionStripe(
    bundleId: number,
    pid: string,
    couponId: number = undefined,
    errorHandler: any = undefined
  ) {
    return this.api.doPayment(
      'stripe',
      'subscription',
      undefined,
      couponId,
      bundleId,
      undefined,
      undefined,
      pid,
      errorHandler
    );
  }

  subscriptionBtcPay(
    bundleId: number,
    couponId: number = undefined,
    errorHandler: any = undefined
  ) {
    return this.api.doPayment(
      'btcpay',
      'subscription',
      'BTC',
      couponId,
      bundleId,
      undefined,
      undefined,
      undefined,
      errorHandler
    );
  }
}
