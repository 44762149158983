import {
  FormSubscriber,
  FormSubscribersListData,
  FormSubscriberStatusEnum,
} from './../../../../models/funnels-subscribers';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  SortingTypeModel,
  FilteringTypeModel,
  Payment_Gateway_Enum,
} from 'src/app/models/portal';
import { FunnelItem } from 'src/app/models/funnels';

@Component({
  selector: 'app-subscribers-list',
  templateUrl: './subscribers-list.component.html',
  styleUrls: ['./subscribers-list.component.css'],
})
export class SubscribersListComponent implements OnInit {
  formSubscriberStatusEnum = FormSubscriberStatusEnum;
  @Input() allSubscribersCount: number;
  @Input() disableFilters: boolean = false;
  @Input() _subscribersData: FormSubscribersListData = {
    availableFunnels: [],
    requested: null,
    subscribers: [],
  };
  @Input() loaded: boolean = false;

  @Output() onFilterByFunnel: EventEmitter<FunnelItem> =
    new EventEmitter<FunnelItem>();

  @Input() listTitlePrefix: string;
  currentPage = 1;
  startIndex = 0;
  numberInPage = 6;
  endIndex = 6;

  // Sort And Filtering Features
  searchWord: string = '';
  sortObject: SortingTypeModel = {
    displayName: 'Newest',
    column: 'time',
    order: 'dsc',
  };
  sortTypes: SortingTypeModel[] = [
    { displayName: 'Newest', column: 'time', order: 'dsc' },
    { displayName: 'Oldest', column: 'time', order: 'asc' },
  ];

  filterObject: FilteringTypeModel = {
    index: 0,
    displayName: 'All',
    column: '',
    value: '',
  };
  filterTypes: FilteringTypeModel[] = [
    {
      index: 0,
      displayName: 'All',
      column: '',
      value: '',
    },
    {
      index: 1,
      displayName: 'Subscribed',
      column: 'status',
      value: FormSubscriberStatusEnum.subscribed,
    },
    {
      index: 2,
      displayName: 'Unconfirmed',
      column: 'status',
      value: FormSubscriberStatusEnum.unconfirmed,
    },
    {
      index: 3,
      displayName: 'Unsubscribed',
      column: 'status',
      value: FormSubscriberStatusEnum.unsubscribed,
    },
  ];

  defaultSelectValue = { id: -1, title: null };
  constructor() {}

  ngOnInit(): void {}

  @Input('subscribersData') set subscribersData(
    subscribers: FormSubscribersListData
  ) {
    if (!!subscribers && !subscribers?.requested) {
      // subscribers.requested = this.defaultSelectValue;
      this._subscribersData.requested = subscribers?.requested;
    }
    this._subscribersData.availableFunnels = subscribers?.availableFunnels;
    this._subscribersData.subscribers = subscribers?.subscribers;
    this.listTitlePrefix = subscribers?.requested?.title;
    // this._subscribersData.requested = subscribers.requested;
  }

  @Input('subscribers') set subscribers(subscribers: FormSubscriber[]) {
    this.subscribersData.subscribers = subscribers;
  }

  @Input('requested') set requested(requested: FunnelItem) {
    this.subscribersData.requested = requested;
  }

  onChangeFunnel(funnel: FunnelItem) {
    this.onFilterByFunnel.emit(funnel);
  }
  filterTypeChange(filterObject: FilteringTypeModel) {
    //
    this.filterObject = filterObject;
    this.resetPagination();
  }

  onSearchWordChange(value: any) {
    this.searchWord = value;
  }

  // Pagination
  resetPagination() {
    this.startIndex = 0;
    this.endIndex = this.numberInPage;
    this.currentPage = 1;
  }

  getArrayLenght(length) {
    return new Array(this.getPagesNumber(length));
  }

  getPagesNumber(length) {
    return Math.ceil(length / this.numberInPage);
  }

  getIndex(pageIndex) {
    if (pageIndex > 0) {
      this.currentPage = pageIndex;
      this.startIndex = (pageIndex - 1) * this.numberInPage;
      this.endIndex = this.startIndex + this.numberInPage;
    }
  }
}
