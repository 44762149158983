import { ChangeDetectorRef, ElementRef, Input, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  StripeCardCvcElement,
  StripeCardElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js';
import { Country } from 'src/app/models/country';
import { ApiService } from '../../../shared/services/lsg.api.service';
import { PaymentService } from '../../../shared/services/payment.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HolderNameRegex } from '../../../shared/services/regex';

@Component({
  selector: 'app-stripepayment',
  templateUrl: './stripepaymentnew.component.html',
  styleUrls: ['./stripepaymentnew.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePaymentComponent implements OnInit {
  clientSecret: string;

  cardHolderElement = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(HolderNameRegex),
  ]);
  cardHolderError: boolean;
  cardElement: StripeCardNumberElement;
  cardExpiryElement: StripeCardExpiryElement;
  cardCVCElement: StripeCardCvcElement;
  error: StripeError = undefined;
  errorMessage: string = undefined;

  startLoading = true;
  cardNumber: any;

  cardNumberStatus: boolean;
  cardExpiryStatus: boolean;
  cardCVCStatus: boolean;

  cardFieldsStatus: {
    number: boolean;
    expiry: boolean;
    cvc: boolean;
    holder: boolean;
  } = { number: false, expiry: false, cvc: false, holder: false };

  cardHolderName: string = '';
  countries: Country[];
  billingExpand = false;
  iagreecard = false;
  isLoading = false;
  isCardFlipped = false;
  creditCardType = 'visa';
  @Output() onStartPayment = new EventEmitter();
  @Output() onFinishPayment = new EventEmitter();
  @Output() onErrorPayment = new EventEmitter();

  @Input() bundleID = undefined;
  @Input() couponID = undefined;

  address: {
    city?: string;
    state?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
  } = {
    city: undefined,
    state: undefined,
    country: undefined,
    line1: undefined,
    line2: undefined,
    postal_code: undefined,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private paymentService: PaymentService
  ) {
    this.addPaymentMethodErrorHandler =
      this.addPaymentMethodErrorHandler.bind(this);
    this.handleGetCountriesError = this.handleGetCountriesError.bind(this);
  }

  ngOnInit(): void {
    this.getCountries();
    this.loadStripeCard();
  }

  async onCountryChanged(value: string) {}

  private async getCountries() {
    this.countries = await this.api
      .getCountries(this.handleGetCountriesError)
      .toPromise();
  }

  resetFormError() {
    this.error = undefined;
    this.errorMessage = undefined;
  }

  handleGetCountriesError(error: HttpErrorResponse) {}

  getCardType() {
    let number = this.cardNumber;
    let re = new RegExp('^4');

    if (number.match(re) != null) return (this.creditCardType = 'visa');

    re = new RegExp('^(34|37)');
    if (number.match(re) != null) return (this.creditCardType = 'amex');

    re = new RegExp('^5[1-5]');
    if (number.match(re) != null) return (this.creditCardType = 'mastercard');

    re = new RegExp('^6011');
    if (number.match(re) != null) return (this.creditCardType = 'discover');

    re = new RegExp('^9792');
    if (number.match(re) != null) return (this.creditCardType = 'troy');

    return (this.creditCardType = 'unknown'); // default type
  }

  async submitPayment() {
    this.cardHolderElement.markAsTouched();
    this.onStartPayment.emit();
    this.isLoading = true;
    const addPaymentMethodResult: { clientSecret: string } = await this.api
      .addPaymentMethod(
        this.bundleID,
        this.couponID,
        null,
        this.addPaymentMethodErrorHandler
      )
      .toPromise();

    this.clientSecret = addPaymentMethodResult.clientSecret;
    const { setupIntent, error } =
      await this.paymentService.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardElement,

          billing_details: {
            name: this.cardHolderName,
            address: this.address,
          },
        },
      });

    if (error) {
      this.error = error;
      switch (error.code) {
        case 'parameter_invalid_empty':
          this.errorMessage = 'Card holder name is incomplete.';
          break;
        case 'incomplete_number':
          this.errorMessage = 'Please enter a valid credit card number.';
          break;
        case 'invalid_expiry_month_past':
          this.errorMessage = 'Your credit card is expired.';
          break;
        case 'incomplete_expiry':
          this.errorMessage = 'Your credit card is invalid.';
          break;
        default:
          this.errorMessage = error.message;
          break;
      }

      this.onErrorPayment.emit(error.message);
    } else {
      await this.delay(3000);
      this.onFinishPayment.emit();
      this.cardElement.clear();
    }
    this.isLoading = false;
  }

  async loadStripeCard() {
    // this.startLoading();
    await this.paymentService.loadStripe();
    const elements = this.paymentService.stripe.elements();

    // Card Number Element
    this.cardElement = elements.create('cardNumber', {
      style: {
        base: {
          lineHeight: '1.429',
        },
      },
    });

    await this.cardElement.mount('#cardNumber');
    this.cardElement.on('change', (event) => {
      this.resetFormError();

      this.creditCardType = event.brand;
      this.cardNumberStatus = event.complete;
      if (!!this.cardNumberStatus) {
        this.cardExpiryElement.focus();
      }
      this.cdr.markForCheck();
    });
    this.cardElement.on('focus', () => {
      this.cardFieldsStatus.number = true;
      this.cdr.markForCheck();
    });

    this.cardElement.on('blur', () => {
      this.cardFieldsStatus.number = false;
      this.cdr.markForCheck();
    });

    // Card CVC Element
    this.cardCVCElement = elements.create('cardCvc');
    await this.cardCVCElement.mount('#cardCvc');
    this.cardCVCElement.on('focus', () => {
      this.cardFieldsStatus.cvc = true;
      this.isCardFlipped = true;
      this.cdr.markForCheck();
    });

    this.cardCVCElement.on('blur', () => {
      this.cardFieldsStatus.cvc = false;
      this.isCardFlipped = false;
      this.cdr.markForCheck();
    });

    this.cardCVCElement.on('change', (event) => {
      this.resetFormError();
      this.cardCVCStatus = event.complete;
      if (!!this.cardCVCStatus) {
        if (this.creditCardType !== 'amex') {
          this.cardCVCElement.blur();
        }
      }
      this.cdr.markForCheck();
    });

    // Card Expiry Element
    this.cardExpiryElement = elements.create('cardExpiry');
    await this.cardExpiryElement.mount('#cardExpiry');

    this.cardExpiryElement.on('change', (event) => {
      this.resetFormError();
      this.cardExpiryStatus = event.complete;
      if (!!this.cardExpiryStatus) {
        this.cardCVCElement.focus();
      }
      this.cdr.markForCheck();
    });

    this.cardExpiryElement.on('focus', () => {
      this.cardFieldsStatus.expiry = true;
      this.cdr.markForCheck();
    });

    this.cardExpiryElement.on('blur', () => {
      this.cardFieldsStatus.expiry = false;
      this.cdr.markForCheck();
    });

    // Loading Statement
    let loadingStepper = 0;
    this.cardExpiryElement.on('ready', () => {
      loadingStepper++;

      if (loadingStepper === 3) {
        this.startLoading = false;
        this.cdr.markForCheck();
      }
    });

    this.cardElement.on('ready', () => {
      loadingStepper++;

      if (loadingStepper === 3) {
        this.startLoading = false;
        this.cdr.markForCheck();
      }
    });

    this.cardCVCElement.on('ready', () => {
      loadingStepper++;

      if (loadingStepper === 3) {
        this.startLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  addPaymentMethodErrorHandler(error: any) {}
}
