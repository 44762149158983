import { Component, Input, OnInit } from '@angular/core';
import { TimePipe } from '../../pipes/dateago.pipe';
import { colorSet } from 'src/app/models/global-classes';
import { isNumber } from 'highcharts';
import { MoneyModel } from 'src/app/models/sales-funnel';
import { timeAgo } from '../../pipes/utils/dateago.utils';
import { formatDuration } from '../../pipes/utils/duration.utils';
@Component({
  selector: 'app-dashboard-stats-card',
  templateUrl: './dashboard-stats-card.component.html',
  styleUrls: ['./dashboard-stats-card.component.css'],
})
export class DashboardStatsCardComponent implements OnInit {
  // Input properties with type annotations
  @Input() growth: number;
  @Input() customTitle: boolean = false;
  @Input() title: string;
  @Input() type: 'percent' | 'number' | 'currency' | 'dateAgo' | 'custom' | 'duration' = 'number';
  @Input() align: 'start' | 'end' | 'center' = 'start';
  @Input() dashNull: boolean = false;
  @Input() primaryNumberClasses = 'fs-2x fw-bold text-gray-800 me-2 lh-1';
  @Input() growthTooltip: string;
  @Input() color: colorSet | string = null;

  // Modified property names for clarity
  _countValue: number;
  _stringValue: string;
  _moneyModelValue: MoneyModel;

  secondsFormat: { count: number; label: string };
  loaded: boolean = true;

  constructor() {}

  // Modified setter for the count property
  @Input() set count(value: number | string | MoneyModel) {
      // Check if the value is a number
    if (isNumber(value)) {
      
      this._countValue = value as number;
      

      if (!!value || value === 0) {
        this.loaded = true;
      } else {
        this.loaded = false;
      }

      if (this.type === 'dateAgo') {
        this.secondsFormat = this.transformIntoAgoFormat(this._countValue * 1000);
      }

      if (this.type === 'duration') {
        this.secondsFormat = this.transformIntoDurationFormat(this._countValue);
      }
    } else if (typeof value === 'string') {
      this._stringValue = value;
    } else {
      // Assume it's an instance of MoneyModel and extract the relevant values
      this._moneyModelValue = value as MoneyModel;
      // If MoneyModel has additional properties that need to be handled, add them here
    }

    // Additional logic for 'seconds' type
    
  }

  ngOnInit(): void {}

  transformIntoAgoFormat(value: number) {
    const timeAgoValue: string = timeAgo(this._countValue * 1000);
    let label = '';
    const split = timeAgoValue?.toString()?.split(' ');
    if (!+split[0]) {
      return { count: null, label: timeAgoValue };
    } else {
      split.forEach((item) => {
        if (!+item) {
          label = label + ' ' + item;
        }
      });
      return { count: +split[0], label };
    }
  }


  transformIntoDurationFormat(value: number) {
    const timeDurationValue: string = formatDuration(this._countValue * 1000);
    let label = '';
    const split = timeDurationValue?.toString()?.split(' ');
    if (!+split[0] && split[0] != '0') {
      return { count: null, label: timeDurationValue };
    } else {
      
      return { count: +split[0], label: split[1] };
    }
  }
}
