import { Component, OnInit, Output, EventEmitter, Input, ContentChild, AfterContentInit, ElementRef } from '@angular/core';
import { FilteringTypeModel, SortingTypeModel } from 'src/app/models/portal';

@Component({
  selector: 'app-head-filter-search',
  templateUrl: './head-filter-search.component.html',
  styleUrls: ['./head-filter-search.component.css'],
})
export class HeadFilterSearchComponent implements OnInit, AfterContentInit {
  @ContentChild('actionsArea') public actionsAreaRef: ElementRef;

  @Input() cardTitle: string;
  @Input() defaultFilter: number = 0;
  @Input() sortTypes: SortingTypeModel[] = [];
  @Input() filterTypes: FilteringTypeModel[] = [];
  @Input() searchBarPlaceHolder: string = 'Search';
  @Input() paginationLabel: string = '';
  @Input() paginationTotal: number = 0;
  @Input() paginationView: number = 0;

  @Output() onSearchWordChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSortTypeChange: EventEmitter<SortingTypeModel> = new EventEmitter<SortingTypeModel>();
  @Output() onFilterTypeChange: EventEmitter<FilteringTypeModel> = new EventEmitter<FilteringTypeModel>();

  searchWord: string = '';
  hasActionsArea = false;

  @Input() sortObject: SortingTypeModel = {
    index: null,
    order: null,
    column: null,
    displayName: null,
  };
  @Input() filterObject: FilteringTypeModel = {
    index: null,
    value: null,
    column: null,
    displayName: null,
  };

  get hasFiltersOrActions(): boolean {
    return !!this.filterTypes.length || this.hasActionsArea;
  }

  ngOnInit(): void {
    if (this.filterTypes?.length) {
      this.filterTypeChange(this.defaultFilter);
    }
  }

  ngAfterContentInit(): void {
    this.hasActionsArea = !!this.actionsAreaRef;
  }

  searchWordChange(): void {
    this.onSearchWordChange.emit(this.searchWord);
  }

  sortTypeChange(index: number): void {
    this.sortObject = { ...this.sortTypes[index], index };
    this.onSortTypeChange.emit(this.sortObject);
  }

  filterTypeChange(index: number): void {
    this.filterObject = { ...this.filterTypes[index], index };
    this.onFilterTypeChange.emit(this.filterObject);
  }
}
