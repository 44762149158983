// reducers/index.ts
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import * as fromOffers from './_features/offers/offers.reducer';

import * as fromProducts from './_features/products/products.reducer';

// Course Related
import * as fromCourse from './_features/products/course/course.reducer';
import * as fromCourseItems from './_features/products/course/course-items/course-items.reducer';
import * as fromCourseDetails from './_features/products/course/details/course-details.reducer';
import * as fromCourseStudents from './_features/products/course/students/course-students.reducer';
import * as fromCourseReports from './_features/products/course/reports/course-reports.reducer';

//Website Related
import * as fromWebsite from './_features/website/website.reducer';
import * as fromWebsiteAnalytics from './_features/website/analytics/website-analytics.reducer';
import * as fromWebsiteBlog from './_features/website/blog/website-blog.reducer';
import * as fromWebsitePayments from './_features/website/payments/website-payments.reducer';


import * as fromNotifications from './_features/notifications/notifications.reducer';
import * as fromAccountSetup from './_features/account-setup/account-setup.reducer';
import * as fromRefundRequests from './_features/refunds/refunds.reducer';
import * as fromDashboard from './_features/dashboard-tracking/dashboard-tracking.reducer';
import * as fromMediaLibrary from './_features/media-library/media-library.reducer';
import * as fromUser from './_features/user/user.reducer';
import * as fromContacts from './_features/contacts/contacts.reducer';



// Website State Combination
export interface WebsiteCompoundState {
  main: fromWebsite.WebsiteState;
  analytics: fromWebsiteAnalytics.WebsiteAnalyticsState;
  blog: fromWebsiteBlog.WebsiteBlogState;
  payments: fromWebsitePayments.WebsitePaymentsState;
}
// Define reducers for the nested WebsiteState
export const websiteCompoundReducers = combineReducers({
  main: fromWebsite.websiteReducer,
  analytics: fromWebsiteAnalytics.websiteAnalyticsReducer,
  blog: fromWebsiteBlog.websiteBlogReducer,
  payments: fromWebsitePayments.websitePaymentsReducer
});

// Course State Combination
export interface CourseCompoundState {
  main: fromCourse.CourseState;
  items: fromCourseItems.CourseItemState;
  details: fromCourseDetails.CourseDetailsState;
  students: fromCourseStudents.CourseStudentsState;
  reports: fromCourseReports.CourseReportsState
}
// Define reducers for the nested WebsiteState
export const courseCompoundReducers = combineReducers({
  main: fromCourse.courseReducer,
  items: fromCourseItems.courseItemReducer,
  details: fromCourseDetails.courseDetailsReducer,
  students: fromCourseStudents.courseStudentsReducer,
  reports: fromCourseReports.courseReportsReducer
});


export interface AppState {
  offers: fromOffers.OffersState;
  products: fromProducts.ProductsState;
  course: CourseCompoundState;
  website: WebsiteCompoundState;
  notifications: fromNotifications.NotificationsState,
  accountSetup: fromAccountSetup.AccountSetupState,
  refundRequests: fromRefundRequests.RefundRequestsState,
  dashboard: fromDashboard.DashboardState,
  mediaLibrary: fromMediaLibrary.MediaLibraryState,
  user: fromUser.UserState,
  contacts: fromContacts.ContactsState
}

export const rootReducer: ActionReducerMap<AppState> = {
  offers: fromOffers.offersReducer, // Combine feature-specific reducers
  products: fromProducts.productsReducer,
  course: courseCompoundReducers,
  website: websiteCompoundReducers, // Use the nested reducers for 'website'
  
  notifications: fromNotifications.notificationsReducer,
  accountSetup: fromAccountSetup.accountSetupReducer,
  refundRequests: fromRefundRequests.refundRequestsReducer,
  dashboard: fromDashboard.dashboardTrackingReducer,
  mediaLibrary: fromMediaLibrary.mediaLibraryReducer,
  user: fromUser.userReducer,
  contacts: fromContacts.contactsReducer
};
